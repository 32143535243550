import React from "react";
import styled from "styled-components";
import ChatMessages from "../ChatInterface/ChatMessages";
import { useNavigate } from "react-router-dom";

// Icons
import logo from "@/assets/img/logo.png"
import bargraph from "@/assets/svg/bar-graph.svg"
import nodes from "@/assets/svg/4Nodes.svg"
import mouseclick from "@/assets/svg/mouseclick.svg"
import rightarrow from "@/assets/svg/rightarrow.svg"

interface GitMainContentProps {
  hasStartedChat: boolean; // Add prop to know when the chat starts
  messages: { user: string; bot: string }[];
  loading: boolean; // Add loading prop
  onSendMessage: (message: string) => void; // Function to send the clicked suggestion as input
  knowledgeSource: string;
  toggleFolderDropdown: () => void; // Prop for triggering folder dropdown
  isOpen: boolean;
  sidebar: boolean;
}

const GitMainContent: React.FC<GitMainContentProps> = ({
  hasStartedChat,
  messages,
  loading,
  isOpen,
  sidebar
}) => {

  const navigate = useNavigate();

  const handleNavigateExplore = () => {
    navigate('/explore');
  };

  if (hasStartedChat) {
    // If the user has started chatting, display the chat messages
    return <ChatMessages messages={messages} loading={loading} isOpen={isOpen} sidebar={sidebar} />; // Pass loading prop here
  }

  return (
    <ContentContainer>
      <WelcomeSection>
        <WelcomeIcon src={require('@/assets/img/logo.png')} alt="Welcome icon" />
        <WelcomeTitle>
          Get Accurate Answers Trained From Your Knowledge Source <br /><br />
        </WelcomeTitle>
        <WelcomeSubtitle>
          Talk to multiple bRAGs at once using our brag-preview-01 context switching model
        </WelcomeSubtitle>
      </WelcomeSection>
      <ExploreButton onClick={handleNavigateExplore}>
        <ExploreText>Or See All bRAGs</ExploreText>
        <ExploreIcon
          src={rightarrow}
          alt="Explore icon"
        />
      </ExploreButton>
    </ContentContainer>
  );
};

const ContentContainer = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 80px;
  margin-top: 70px;
  overflow-y: auto;
  display: flex;
`;

const WelcomeSection = styled.section`
  text-align: center;
  margin-bottom: 48px;
`;

const WelcomeIcon = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: 5px;
`;

const WelcomeTitle = styled.h1`
  font-size: 23px;
  font-weight: 500;
  color: var(--color-white-solid, #fff);
  margin-bottom: 11px;
`;

const WelcomeSubtitle = styled.p`
  font-size: 15px;
  // font-weight: 600;
  color: var(--color-grey-64, #a3a3a3);
  // font-weight: regular;
`;

const SuggestionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  width: 100%;
  max-width: 1280px;
  margin-bottom: 32px;
`;

const SuggestionCard = styled.div`
  border: 1px solid var(--color-grey-15, #262626);
  border-radius: 12px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 0.3s ease;
  justify-content: space-around;

  &:hover {
    background-color: var(--color-grey-30, #333333); /* Change background on hover */
    // background-color: #262730;
  }
`;

const SuggestionIcon = styled.img`
  width: 20px;
  height: 20px;
  // margin-bottom: 12px;
`;

const SuggestionTitle = styled.h3`
  font-size: 16px;
  color: var(--color-grey-83, #d4d4d4);
  margin-bottom: 16px;
`;

const SuggestionTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  `;

const SuggestionTag = styled.span`
  font-size: 14px;
  color: var(--color-grey-45, #737373);
  margin-right: 8px;
`;

const ExploreButton = styled.button`
  display: flex;
  align-items: center;
  background: var(--color-grey-83, #d4d4d4);
  border: none;
  border-radius: 9999px;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-black-solid, #000);
  // color: #262730;
  cursor: pointer;
`;

const ExploreText = styled.span`
  margin-right: 8px;
`;

const ExploreIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export default GitMainContent;

import React from "react";
import { FiX } from "react-icons/fi";
import styled from "styled-components";

interface SidebarDarkProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

// const sources = [
//   {
//     "logo": "path_to_bu_logo.png",
//     "sourceLabel": "Boston University",
//     "title": "Taha H. Ababou | Innovate@BU - Boston University",
//     "description": "Taha H. Ababou First-Year Innovation Fellowship. Title First-Year Innovation Fellowship; Education ENG’24..."
//   },
//   {
//     "logo": "path_to_github_logo.png",
//     "sourceLabel": "GitHub",
//     "title": "tahababou12 (Taha Ababou) - GitHub",
//     "description": "Hi 👋, I'm Taha Ababou Full Stack Developer from Morocco living his best life in Boston..."
//   },
//   {
//     "logo": "path_to_youtube_logo.png",
//     "sourceLabel": "YouTube",
//     "title": "Boi Bésalom - Alliel & David Hababou (Cover Flowers)",
//     "description": "March 16, 2024 — Boi Bésalom - Alliel & David Hababou (Cover Flowers) Miley Cyrus..."
//   },
//   {
//     "logo": "path_to_linkedin_logo.png",
//     "sourceLabel": "LinkedIn",
//     "title": "Taha Ababou - LinkedIn",
//     "description": "Experienced Full Stack Developer skilled in React Native, JavaScript, and backend technologies..."
//   },
//   {
//     "logo": "path_to_twitter_logo.png",
//     "sourceLabel": "Twitter",
//     "title": "Taha Ababou | Twitter",
//     "description": "Sharing insights and experiences as a Full Stack Developer and Software Engineering student at BU..."
//   },
//   {
//     "logo": "path_to_bu_logo.png",
//     "sourceLabel": "Boston University",
//     "title": "Taha H. Ababou | Innovate@BU - Boston University",
//     "description": "Taha H. Ababou First-Year Innovation Fellowship. Title First-Year Innovation Fellowship; Education ENG’24..."
//   },
//   {
//     "logo": "path_to_github_logo.png",
//     "sourceLabel": "GitHub",
//     "title": "tahababou12 (Taha Ababou) - GitHub",
//     "description": "Hi 👋, I'm Taha Ababou Full Stack Developer from Morocco living his best life in Boston..."
//   },
//   {
//     "logo": "path_to_youtube_logo.png",
//     "sourceLabel": "YouTube",
//     "title": "Boi Bésalom - Alliel & David Hababou (Cover Flowers)",
//     "description": "March 16, 2024 — Boi Bésalom - Alliel & David Hababou (Cover Flowers) Miley Cyrus..."
//   },
//   {
//     "logo": "path_to_linkedin_logo.png",
//     "sourceLabel": "LinkedIn",
//     "title": "Taha Ababou - LinkedIn",
//     "description": "Experienced Full Stack Developer skilled in React Native, JavaScript, and backend technologies..."
//   },
//   {
//     "logo": "path_to_twitter_logo.png",
//     "sourceLabel": "Twitter",
//     "title": "Taha Ababou | Twitter",
//     "description": "Sharing insights and experiences as a Full Stack Developer and Software Engineering student at BU..."
//   }
// ]

const sources = [
  {
    "logo": require("../../assets/img/brag.jpeg"),
    "sourceLabel": "LangChain Documentation",
    "title": "LangChain Python Documentation",
    "description": "Official documentation for LangChain, providing details on its features, including document loaders and more.",
    "url": "https://langchain.readthedocs.io/"
  },
  {
    "logo": require("../../assets/img/brag.jpeg"),
    "sourceLabel": "GitHub",
    "title": "LangChain GitHub Repository",
    "description": "Explore the open-source LangChain repository for Python, including examples and code for document loaders.",
    "url": "https://github.com/hwchase17/langchain"
  },
  {
    "logo": require("../../assets/img/brag.jpeg"),
    "sourceLabel": "Medium",
    "title": "Understanding RAG: Combining Retrieval and Generation",
    "description": "A blog post explaining the Retrieval-Augmented Generation (RAG) approach for building advanced chatbots.",
    "url": "https://medium.com/understanding-rag-chatbots"
  },
  {
    "logo": require("../../assets/img/brag.jpeg"),
    "sourceLabel": "YouTube",
    "title": "LangChain Tutorial: Document Loaders in Python",
    "description": "A step-by-step video guide on using LangChain's document loader with Python.",
    "url": "https://www.youtube.com/watch?v=langchain-tutorial"
  },
  {
    "logo": require("../../assets/img/brag.jpeg"),
    "sourceLabel": "LangChain API Reference",
    "title": "Document Loaders API - LangChain",
    "description": "API reference for document loaders in LangChain, including available classes and methods.",
    "url": "https://langchain.readthedocs.io/en/latest/document_loaders.html"
  }
];

const SourcesSidebar: React.FC<SidebarDarkProps> = ({ isOpen, toggleSidebar }) => {
  return (
    <SidebarContainer isOpen={isOpen}>
      {isOpen && (
        <>
          <Logo>
            <LogoText>Citations</LogoText>
            <ToggleButton onClick={toggleSidebar} isOpen={isOpen}>
              <FiX />
            </ToggleButton>
          </Logo>
          <SourcesContainer>
            {sources.map((item, index) => (
              <SourceBox key={index}>
                <SourceLogo src={item.logo} alt={`${item.sourceLabel} Logo`} />
                <SourceText>
                  <SourceLabel>{item.sourceLabel}</SourceLabel>
                  <SourceTitle>{item.title}</SourceTitle>
                  <SourceDescription>{item.description}</SourceDescription>
                </SourceText>
              </SourceBox>
            )
            )}
          </SourcesContainer>
        </>
      )}
    </SidebarContainer>
  );
};

const SidebarContainer = styled.aside<{ isOpen: boolean }>`
  width: ${(props) => (props.isOpen ? "400px" : "0px")};  /* Sidebar width changes based on state */
  padding: ${(props) => (props.isOpen ? "20px" : "0px")};  /* Padding width changes based on state */
  background: #0a0a0a;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #2b2b2b;
`;

const ToggleButton = styled.button<{ isOpen: boolean }>`
  position: absolute;
  // top: 50%;
  right: 15px;
  transition: width 0.7s ease;  /* Smooth transition */
  background-color: var(--color-grey-10, #18181b);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  // margin-bottom: 10px;
`;

const LogoText = styled.h2`
  font-weight: 700;
  font-size: 18px;
  color: #fafafa;
  // margin: 0;
`;

const SourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #2b2b2b;
  overflow-y: scroll;  /* Allows vertical scrollbar */
  // padding-right: 8px;  /* Adds space for scrollbar */
  scrollbar-width: none;  /* Hides scrollbar for Firefox */
  ::-webkit-scrollbar {
    display: none;  /* Hides scrollbar for Chrome, Safari, and Opera */
  }
`;

const SourceBox = styled.a`
  display: flex;
  align-items: flex-start;
  background-color: #111;
  padding: 16px;
  margin-bottom: 12px;
  border-radius: 8px;
  cursor: pointer;
  
  &:hover {
    background-color: #1a1a1a;
  }
`;

const SourceLogo = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 16px;
`;

const SourceText = styled.div`
  display: flex;
  flex-direction: column;
`;

const SourceLabel = styled.span`
  color: #b3b3b3;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 6px;
`;

const SourceTitle = styled.h3`
  color: #fafafa;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 6px 0;
`;

const SourceDescription = styled.p`
  color: #b3b3b3;
  font-size: 13px;
  margin: 0;
  line-height: 1.4;
`;

export default SourcesSidebar;

/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

interface ThirdPartyGitImportProps {
  // Add any props if needed
}

const ThirdPartyGitImport: React.FC<ThirdPartyGitImportProps> = () => {
  return (
    <StyledSection>
      <h2 className="section-title">Or Import Third-Party Git Repository</h2>
      <form className="import-form">
        <div className="form-group">
          <label htmlFor="git-repo-url">
            Git Repository URL <span className="required">*</span>
          </label>
          <input
            type="text"
            id="git-repo-url"
            placeholder="https://github.com/user/repository"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="git-token">
            Git Token (required for private repositories)
          </label>
          <input
            type="password"
            id="git-token"
            placeholder="ghp_*****"
            // required
          />
        </div>
        <button type="submit" className="import-button" onClick={() => window.location.href = '/git-chat'}>
          Import Git Repository
        </button>
      </form>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  border-radius: 12px;
  border: 1px solid #333;
  background-color: #000;
  display: flex;
  width: 42%;
  flex-direction: column;
  margin: 0 auto;
  padding: 38px 33px;

  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    margin-top: 24px;
    padding: 0 20px;
  }

  .section-title {
    letter-spacing: -0.96px;
    color: #ededed;
    font: 600 24px/32px Geist, sans-serif;
    margin-bottom: 24px;

    @media (max-width: 640px) {
      padding-bottom: 35px;
    }
  }

  .import-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  label {
    color: #ededed;
    font-size: 14px;
  }

  .required {
    color: #ef4444;
  }

  input {
    border-radius: 6px;
    background-color: #0a0a0a;
    border: 1px solid rgba(255, 255, 255, 0.14);
    color: #8f8f8f;
    font: 400 13px Geist, sans-serif;
    padding: 12px 14px;

    &::placeholder {
      color: #8f8f8f;
    }
  }

  .import-button {
    border-radius: 6px;
    background-color: #ededed;
    color: #0a0a0a;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 12px;
    border: none;
    cursor: pointer;
    margin-top: 26px;
    align-self: stretch;
  }
`;

export default ThirdPartyGitImport;

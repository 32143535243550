import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import WaitListForm from "./WaitListForm";

const CallToAction: React.FC = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleNavigate = () => {
    navigate('/chat'); // This will navigate to the ChatInterface page
  };

  return (
    <Section>
      <Container>
        <Content>
          {/* <Title>Get started for free.</Title> */}
          <Title>Join bRAG AI's waitlist.</Title>

          {/* <ButtonGroup>
            <ViewButton>View bRAG</ViewButton>
              <CreateButton onClick={handleNavigate}>
                Create or chat with a bRAG
                <ButtonIcon
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d304f31d8370688ed744ef77e76554c4b033c681162906d19bdbce8b20dca04?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88"
                  alt=""
                />
              </CreateButton>
          </ButtonGroup> */}
        </Content>
          <WaitListForm placeholder="Enter your email" buttonText="Join Waitlist"/>
      </Container>
    </Section>
  );
};

const Section = styled.section`
  align-self: stretch;
  display: flex;
  // margin-top: 96px;
  flex-direction: column;
  overflow: hidden;
  font-family: var(--font-family-Font-1, Inter);
  color: var(--brag-ai-white, var(--color-white-solid, #fff));
  justify-content: center;
  padding: 100px 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 100px 0;
  }
`;

const Container = styled.div`
  border-radius: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 82px 80px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 20px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  text-align: center;
  font-size: var(--font-size-48, 48px);
  font-weight: var(--font-weight-600, 600);
  line-height: var(--font-size-48, 48px);
  letter-spacing: var(--letter-spacing--0_72, -0.72px);
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

const ButtonGroup = styled.div`
  align-self: center;
  display: flex;
  gap: 7px;
  font-size: 14px;
  font-weight: var(--font-weight-400, 400);
  line-height: var(--line-height-20, 20px);
  justify-content: center;
`;

const Button = styled.button`
  align-self: stretch;
  border-radius: 6px;
  background-color: rgba(23, 23, 23, 1);
  min-height: 36px;
  padding: 8px 16px;
  border: none;
  color: inherit;
  cursor: pointer;
`;

const ViewButton = styled(Button)``;

// const CreateButton = styled(Button)`
//   display: flex;
//   align-items: center;
//   gap: 8px;
//   justify-content: center;
// `;

const CreateButton = styled.button`
  cursor: pointer; /* Ensure cursor is set to pointer */
  background-color: rgba(23, 23, 23, 1);
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* Ensure pointer events are enabled */
  pointer-events: auto;

  &:hover {
    background-color: #333; /* Optional hover effect */
  }
`;

const ButtonIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  align-self: stretch;
  margin: auto 0;
`;

export default CallToAction;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FeatureCard from "./FeatureCard";
import FeatureCardMobile from "./FeatureCardMobile";

import a from "@/assets/img/home/_a.png";
import b from "@/assets/img/home/_b.png";
import c from "@/assets/img/home/_c.png";
import d from "@/assets/img/home/_d.png";

interface FeatureData {
  title: string;
  description: string;
  imageSrc: string;
}

const features: FeatureData[] = [
  {
    title: "Chat with models that don't make stuff up",
    description:
      "Find bRAGs on a range of topics and trained on various knowledge sources.",
    imageSrc: a,
    // "https://cdn.builder.io/api/v1/image/assets/TEMP/9835766b0c09cee31501f77dea8f711dcff0bb07222685df1cf00de3c8f96247?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
  },
  {
    title: "Explore countless bRAGs",
    description:
      "Ensure accuracy with up-to-date answers, directly sourced from reliable references you select.",
    imageSrc: b,
    // "https://cdn.builder.io/api/v1/image/assets/TEMP/9bd2ab0bb8bafdb53611814bfe575fb9bed593d012dafab46e3c62320c7fcf4a?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
  },
  {
    title: "Can't find a bRAG? Train one.",
    description:
      "Training a private or public bRAG is as easy as drag and dropping a link to a web source.",
    imageSrc: c,
    // "https://cdn.builder.io/api/v1/image/assets/TEMP/07f45852968fe4fb2efae3b1d6b75ba2a32bdb91d518431d45e493ddb19354b8?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
  },
  {
    title: "Organize and gain control over your code",
    description:
      "Import Git repositories and interact with your code directly, turning natural language into actionable insights and queries.",
    imageSrc: d,
    // "https://cdn.builder.io/api/v1/image/assets/TEMP/83da120d7db9980a7661c42fce4dab3a218bc90d46427fef8bb5d64ed75b8cf0?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
  },
];

const FeatureSection: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Section>
      {features.map((feature, index) => (
        isMobile ? (
          <FeatureCardMobile
            key={index}
            {...feature}
            isReverse={index % 2 === 0}
          />
        ) : (
          <FeatureCard
            key={index}
            {...feature}
          />
        )
      ))}
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 160px;
  margin-top: 100px;
  @media (max-width: 991px) {
    gap: 40px;
    margin-top: 40px;
  }
`;

export default FeatureSection;

import React, { useState } from "react";
import styled from "styled-components";

interface ChatInputProps {
  onSendMessage: (message: string) => void;
  loading: boolean; // Disable input when loading
  knowledgeSource: string;
  sidebar: boolean; // Add sidebar prop to determine the theme
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, loading, knowledgeSource, sidebar }) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [textAreaHeight, setTextAreaHeight] = useState<number>(24); // Initial height for one line

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // Temporarily reset height to 'auto' to correctly calculate scrollHeight, then set it back to the calculated height
    e.target.style.height = "auto";

    // Calculate the new height based on scrollHeight (actual content height)
    const newHeight = e.target.scrollHeight;

    setTextAreaHeight(newHeight);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // Prevent the default behavior of adding a new line
      e.preventDefault();
      if (inputValue.trim() && !loading) { // Prevent submitting if loading or typing
        // Send the message
        onSendMessage(inputValue);
        setTextAreaHeight(24); // Reset textarea height
        setInputValue(""); // Clear the input
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <InputWrapper>
      <InputContainer>
        <InputForm onSubmit={(e) => e.preventDefault()} sidebar={sidebar}>
          <TextArea
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown} // Capture key press events
            style={{ height: `${textAreaHeight}px` }} // Dynamically change height
            onInput={handleInput}
            placeholder={`Message bRAG | ${knowledgeSource}`}
            disabled={loading} // Disable input when loading or typing
          />
          <SendButton
            type="button"
            onClick={() => {
              if (inputValue.trim() && !loading) { // Prevent sending if loading or typing
                onSendMessage(inputValue);
                setInputValue("");
                setTextAreaHeight(24); // Reset textarea height
              }
            }}
            disabled={loading} // Disable button when loading or typing
          >
            <SendIcon
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/861306cff3e0bdde8dab793c58308b1e36a51478635fc5fcd73581556241cb37?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88"
              alt="Send message"
            />
          </SendButton>
        </InputForm>
      </InputContainer>
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%; /* Full width to allow centering */
`;

const InputContainer = styled.div`
  width: 50%; /* Set the desired width of the input bar */
  min-width: 300px; /* Minimum width */
  margin-bottom: 40px;
  position: fixed; /* Fix the container in place */
  bottom: 0; /* Attach it to the bottom */
`;

const InputForm = styled.form<{ sidebar: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.sidebar ? "#e0e0e0" : "#2f2f2f")}; // Change background color based on sidebar
  border-radius: 18px;
  padding: 8px;
`;

const TextArea = styled.textarea`
  flex: 1;
  background: transparent;
  border: none;
  color: var(--color-azure-65, #9ca3af);
  font-family: system-ui;
  font-size: 16px;
  resize: none;
  align-content: center;
  outline: none;
  padding: 0 0 0 8px; /* Padding on the left */
  margin: 0;
  min-height: 24px;
  max-height: 168px; /* Limit to 7 rows (7 * 24px) */
  overflow-y: auto; /* Allow scrolling if more than 7 rows */
  padding-left: 8px; /* Placeholder padding to match input padding */

  &::placeholder {
    color: var(--color-azure-65, #9ca3af);
  }

  &:disabled {
    background-color: #2c2c2c; /* Slightly dimmed background for disabled state */
  }

  &::-webkit-scrollbar-track {
    background: #222; /* Background of the scrollbar track */
  }

  &::-webkit-scrollbar-thumb {
    background: #555; /* Color of the scrollbar thumb */
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #777; /* Color of the scrollbar thumb on hover */
  }

  /* Scrollbar styles for Firefox */
    scrollbar-color: #555 #0a0a0a; /* thumb color, track color */
    scrollbar-width: thin; /* Thin scrollbar */

    `;

const SendButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin - left: 8px;
    &:disabled {
    cursor: not - allowed;
    opacity: 0.5;
  }
`;

const SendIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-top: 2px;
`;

export default ChatInput;

import React from "react";
import styled from "styled-components";
import { DiscardButton } from "./DiscardButton";
import { NavProgressItem } from "./NavProgressItem";
import { KnowledgeBaseForm } from "./KnowledgeBaseForm";

interface CreationPageProps {
  // Add any props if needed
}

const CreationPage: React.FC<CreationPageProps> = () => {
  const navProgressItems = [
    {
      title: "bRAG Information",
      description: "Enter the bRAG details",
      step: 1,
    },
    {
      title: "Knowledge Source",
      description: "Configure the knowledge source",
      step: 2,
    },
  ];

  return (
    <PageWrapper>
      <ContentContainer>
        <MainContent>
          <SidePanel>
            <DiscardButton />
            <PanelContent>
              <PanelTitle>Create a bRAG</PanelTitle>
              <PanelDescription>
                Before requesting a bRAG, please first check if
                a similar bRAG exists. Redundant and
                inappropriate requests will be automatically
                rejected. Once trained, the bRAG will be added
                to your page and you may receive an email
                from our team notifying you.
              </PanelDescription>
            </PanelContent>
            <NavProgress>
              {navProgressItems.map((item, index) => (
                <NavProgressItem key={index} {...item} />
              ))}
            </NavProgress>
          </SidePanel>
          <FormContainer>
            <KnowledgeBaseForm />
          </FormContainer>
        </MainContent>
      </ContentContainer>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  // height: 100vh; /* Fix page height */
  @media (max-width: 991px) {
    padding: 100px 20px 0;
  }
`;

const ContentContainer = styled.main`
  display: flex;
  width: 100%;
  max-width: 880px;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin: auto;
  height: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-bottom: 100px;
  }
`;

const MainContent = styled.section`
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: start;
  flex: 1;
  // height: 100%; /* Ensure it takes full height */
  align-content: center;
  overflow: hidden; /* Prevents content overflow */
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SidePanel = styled.aside`
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(40, 40, 40, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05),
    0px 2px 10px 0px rgba(0, 0, 0, 0.2),
    0px 0px 1px 1px rgba(255, 255, 255, 0.15) inset;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  width: 344px;
  padding: 25px 33px;
  align-self: flex-start; /* Prevents stretching to the bottom */
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const PanelContent = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  font-family: Geist, sans-serif;
  @media (max-width: 991px) {
    margin-right: 3px;
  }
`;

const PanelTitle = styled.h2`
  font-size: 20px;
  color: #fff;
  font-weight: 600;
`;

const PanelDescription = styled.p`
  margin-top: 4px;
  padding-top: 8px;
  font-size: 13px;
  color: #9ca3af;
  line-height: 20px;
`;

const NavProgress = styled.nav`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: start;
`;

const FormContainer = styled.div`
  flex: 1;
  max-height: 80vh; /* Prevent form from growing beyond 80% of the viewport */
  overflow-y: auto; /* Scroll within the form area if necessary */
`;

export default CreationPage;

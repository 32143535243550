import React, { useState } from "react";
import styled from "styled-components";

// Icons
import folder from "@/assets/svg/folder.svg"
import dropdown from "@/assets/svg/dropdown.svg"

interface HeaderProps {
  knowledgeSource: string;
  isFolderDropdownOpen: boolean;
  toggleFolderDropdown: () => void; // Prop for toggling folder dropdown
  sidebar: boolean; // Add sidebar prop to determine the theme
}

const Header: React.FC<HeaderProps> = ({ knowledgeSource, isFolderDropdownOpen, toggleFolderDropdown, sidebar }) => {
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  // const [isFolderDropdownOpen, setIsFolderDropdownOpen] = useState(false);
  // const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

  const toggleModelDropdown = () => setIsModelDropdownOpen(!isModelDropdownOpen);
  // const toggleFolderDropdown = () => setIsFolderDropdownOpen(!isFolderDropdownOpen);
  // const toggleUserDropdown = () => setIsUserDropdownOpen(!isUserDropdownOpen);

  return (
    <HeaderContainer>
      {/* Left Section: Model Selection */}
      <LeftSection>
        <ModelSelector onClick={toggleModelDropdown}>
          <ModelName sidebar={sidebar}>bRAG-preview-01</ModelName>
          <DropdownArrow>
            <img src={dropdown} alt="Dropdown icon" />
          </DropdownArrow>
          {/* <ModelName>Everything About Taha Ababou's Career</ModelName> */}
          {/* <ModelName>{knowledgeSource}</ModelName> */}
        </ModelSelector>
        {isModelDropdownOpen && (
          <DropdownMenu className="model-dropdown">
            <DropdownItem sidebar={sidebar}>bRAG-preview-01</DropdownItem>
          </DropdownMenu>
        )}
        <PoweredBy sidebar={sidebar}>Powered by GPT-4-turbo</PoweredBy>
      </LeftSection>

      {/* Right Section: Folder and Profile */}
      <RightSection>
        <FolderContainer sidebar={sidebar} onClick={toggleFolderDropdown}>
          <FolderIcon
            src={folder}
            alt="Folder icon"
            sidebar={sidebar}
          />
          {/* <FolderText>{knowledgeSource}</FolderText> */}
          <FolderText sidebar={sidebar}>Default</FolderText>
          <DropdownArrow>
            <img src={dropdown} alt="dropdown" />
          </DropdownArrow>
        </FolderContainer>
        {isFolderDropdownOpen && (
          <DropdownMenu className="folder-dropdown">
            <DropdownItem sidebar={sidebar}>{knowledgeSource}</DropdownItem>
            <DropdownItem sidebar={sidebar}>Folder B</DropdownItem>
          </DropdownMenu>
        )}
        <AvatarCircle>T</AvatarCircle>
        {/* <AvatarCircle onClick={toggleUserDropdown}>T</AvatarCircle>
        {isUserDropdownOpen && (
          <DropdownMenu className="user-dropdown">
            <DropdownItem>Profile Settings</DropdownItem>
            <DropdownItem>Log Out</DropdownItem>
          </DropdownMenu>
        )} */}
      </RightSection>
    </HeaderContainer>
  );
};

// Main Header container
const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0px 20px;
  // background-color: var(--color-grey-10, #222);
  // border-bottom: 1px solid var(--color-grey-15, #0a0a0a);
  `;

// Left section containing model and powered by text
const LeftSection = styled.div`
  display: flex;
  flex-direction: column; /* Stack model and powered by text */
  position: relative; /* To position dropdown under model */
`;

// Model selector button and text
const ModelSelector = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: var(--color-white-solid, #fff);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  // position: relative; /* Relative for dropdown to appear below */
`;

const ModelName = styled.span<{ sidebar: boolean }>`
  margin-right: 8px;
  color: ${(props) => (props.sidebar ? "#000" : "#fff")};
`;

// Dropdown arrow styling
const DropdownArrow = styled.span`
  color: var(--color-white-solid, #fff);
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: auto;
`;

// Powered by text
const PoweredBy = styled.span<{ sidebar: boolean }>`
  font-size: 14px;
  color: ${(props) => (props.sidebar ? "#000" : "#a3a3a3")};
  margin-top: 8px;
  margin-left: 4px;
`;

// Right section containing folder and user avatar
const RightSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

// Folder container with icon, text, and dropdown arrow
const FolderContainer = styled.div<{ sidebar: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.sidebar ? "#e0e0e0" : "var(--color-grey-15, #262626)")};
  border-radius: 12px; /* More rounded corners */
  padding: 8px 12px; /* Tighter padding */
  margin-right: 16px;
  cursor: pointer;
  position: relative;
`;

// Folder icon styling
const FolderIcon = styled.img<{ sidebar: boolean }>`
  width: 26px; /* Smaller icon */
  height: 26px;
  margin-right: 6px;
  ${({ sidebar }) => sidebar && `
    filter: invert(1) brightness(0); /* Example styling for sidebar */
  `}
`;

// Folder text styling
const FolderText = styled.span<{ sidebar: boolean }>`
  color: ${(props) => (props.sidebar ? "#000" : "#fff")};
  font-size: 14px; /* Slightly smaller font */
  margin-right: 8px;
`;

// Avatar circle for user
const AvatarCircle = styled.div`
  width: 40px; /* Slightly larger */
  height: 40px;
  border-radius: 50%;
  background-color: #3a8cff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  cursor: default;
  position: relative;
`;

// Dropdown Menu for Model, Folder, and User
const DropdownMenu = styled.div`
  position: absolute;
  top: 48px; /* Adjusted for positioning below folder */
  background-color: var(--color-grey-15, #262626);
  // background-color: #262730;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

// Dropdown item for folder/user settings
const DropdownItem = styled.div<{ sidebar: boolean }>`
  color: ${(props) => (props.sidebar ? "#000" : "#fff")};
  padding: 8px 0;
  cursor: pointer;
  &:hover {
    background-color: var(--color-grey-25, #3a3a3a);
  }
`;


export default Header;

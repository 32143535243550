/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

interface WorkspaceSectionProps {
  // Add any props if needed
}

const WorkspaceSection: React.FC<WorkspaceSectionProps> = () => {
  return (
    <WorkspaceSectionContainer>
      <IconContainer>
        <IconWrapper>
          <Icon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e36fe502e19b71f2112973a0cf7930f17aab323a07c79b073e6c16355165798e?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88"
            alt=""
          />
        </IconWrapper>
      </IconContainer>
      <WorkspaceHeading>Workspace</WorkspaceHeading>
    </WorkspaceSectionContainer>
  );
};

const WorkspaceSectionContainer = styled.section`
  background-color: #0a0a0a;
  display: flex;
  min-height: 22px;
  width: 100%;
  align-items: center;
  overflow: hidden;
  justify-content: flex-start;
`;

const IconContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 20px;
  margin: auto 0;
  padding: 0 2px;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Icon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
`;

const WorkspaceHeading = styled.h2`
  align-self: stretch;
  min-width: 23px;
  overflow: hidden;
  color: #ccc;
  white-space: nowrap;
  text-transform: uppercase;
  margin: auto 0;
  font: 700 11px/22px "SF Pro Text", sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export default WorkspaceSection;

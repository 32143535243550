/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

interface HeaderProps {
  // Add any props if needed
}

const Header: React.FC<HeaderProps> = () => {
  return (
    <HeaderContainer>
      <TabContainer>
        <TablistActiveViewSwitcher>
          <TabExplorer>
            <TabContent />
          </TabExplorer>
        </TablistActiveViewSwitcher>
      </TabContainer>
      <ActionsContainer>
        <ToolbarExplorerActions>
          <ButtonContainer>
            <ButtonMenuViewsAndMoreActions>
              <IconWrapper>
                <Icon
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d55cadc0207d44d4440a7601ba6c6a3f4bebbe9d6164b73a7f9fad03fb7cd41?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88"
                  alt=""
                />
              </IconWrapper>
            </ButtonMenuViewsAndMoreActions>
          </ButtonContainer>
        </ToolbarExplorerActions>
      </ActionsContainer>
      <SidebarToggleContainer>
        <Toolbar>
          <ButtonContainer>
            <ButtonHideSecondarySideBar>
              <IconWrapper>
                <Icon
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/1fd59e46a4fedafcdaa6f704a0f98582e7495386e09b434a71949d03b5d90075?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88"
                  alt=""
                />
              </IconWrapper>
            </ButtonHideSecondarySideBar>
          </ButtonContainer>
        </Toolbar>
      </SidebarToggleContainer>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  background-color: #0a0a0a;
  display: flex;
  min-height: 35px;
  width: 100%;
  overflow: hidden;
  justify-content: flex-start;
  padding: 0 8px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  flex: 1;
  flex-basis: 0%;
`;

const TablistActiveViewSwitcher = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
`;

const TabExplorer = styled.div`
  align-self: stretch;
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  padding: 1px 2px;
`;

const TabContent = styled.div`
  align-self: stretch;
  display: flex;
  min-height: 34px;
  width: 22px;
  margin: auto 0;
`;

const ActionsContainer = styled.div`
  align-self: flex-start;
  display: flex;
  min-height: 35px;
  padding-left: 5px;
  flex-direction: column;
  justify-content: center;
  width: 31px;
`;

const ToolbarExplorerActions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  height: 100%;
  padding: 7px 4px 7px 0;
`;

const ButtonContainer = styled.div`
  align-self: stretch;
  display: flex;
  width: 22px;
  flex-direction: column;
  justify-content: center;
  max-width: 150px;
  margin: auto 0;
`;

const ButtonMenuViewsAndMoreActions = styled.button`
  border-radius: 5px;
  align-self: stretch;
  display: flex;
  min-height: 22px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
  padding: 3px;
  background: none;
  border: none;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 16px;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Icon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
`;

const SidebarToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 26px;
`;

const Toolbar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
  padding: 7px 0;
`;

const ButtonHideSecondarySideBar = styled.button`
  border-radius: 5px;
  display: flex;
  min-height: 22px;
  width: 22px;
  align-items: center;
  justify-content: flex-start;
  padding: 3px;
  background: none;
  border: none;
  cursor: pointer;
`;

export default Header;

import React from "react";
import styled from "styled-components";
import ChatMessages from "./ChatMessages";
import { useNavigate } from "react-router-dom";

// Icons
import logo from "@/assets/img/brag-w.png";
import logoDark from "@/assets/img/brag-d.png";
import bargraph from "@/assets/svg/bar-graph.svg"
import nodes from "@/assets/svg/4Nodes.svg"
import mouseclick from "@/assets/svg/mouseclick.svg"
import rightarrow from "@/assets/svg/rightarrow.svg"

interface MainContentProps {
  hasStartedChat: boolean; // Add prop to know when the chat starts
  messages: { user: string; bot: string }[];
  loading: boolean; // Add loading prop
  onSendMessage: (message: string) => void; // Function to send the clicked suggestion as input
  knowledgeSource: string;
  toggleFolderDropdown: () => void; // Prop for triggering folder dropdown
  isOpen: boolean;
  sidebar: boolean; // Add sidebar prop to determine the theme
}

const MainContent: React.FC<MainContentProps> = ({
  hasStartedChat,
  messages,
  loading,
  onSendMessage,
  knowledgeSource,
  toggleFolderDropdown,
  isOpen,
  sidebar
}) => {

  const suggestions = [
    {
      icon: sidebar ? logoDark : logo,
      title: "What inspired Taha Ababou to pursue a career in software development?",
      tags: ["Inspiration | Career Path"],
    },
    {
      icon: bargraph,
      title: "What are Taha Ababou’s key skills and areas of expertise?",
      tags: ["Skills | Expertise"],
    },
    {
      icon: nodes,
      title: "What projects has Taha Ababou worked on in the past?",
      tags: ["Projects | Experience"],
    },
    {
      icon: mouseclick,
      title: "How does Taha Ababou stay up to date with the latest trends in technology?",
      tags: ["Learning | Technology Trends"],
    },
  ];

  const handleSuggestionClick = (title: string) => {
    onSendMessage(title); // Submit the clicked suggestion as input
  };

  const navigate = useNavigate();

  const handleNavigateExplore = () => {
    navigate('/explore');
  };

  if (hasStartedChat) {
    // If the user has started chatting, display the chat messages
    return <ChatMessages messages={messages} loading={loading} isOpen={isOpen} sidebar={sidebar} />; // Pass loading prop here
  }

  return (
    <ContentContainer>
      <WelcomeSection>
        <WelcomeIcon src={sidebar ? require('@/assets/img/brag-d.png') : require('@/assets/img/brag-w.png')} alt="Welcome icon" />
        <WelcomeTitle sidebar={sidebar}>
          Get Accurate Answers Trained From Your Knowledge Source <br /><br />
          {/* <Link
            to="#"
            style={{ color: "white", fontWeight: "bold" }}
            onClick={toggleFolderDropdown} // Call the toggle function on click
          >
            "{knowledgeSource}"
          </Link> */}
        </WelcomeTitle>
        <WelcomeSubtitle sidebar={sidebar}>
          {/* You may experience some instability as bRAG-01 is in public preview */}
          {/* {knowledgeSource} */}
          Talk to multiple bRAGs at once using our brag-preview-01 context switching model
        </WelcomeSubtitle>
      </WelcomeSection>
      <SuggestionsGrid>
        {suggestions.map((suggestion, index) => (
          <SuggestionCard
            key={index}
            onClick={() => handleSuggestionClick(suggestion.title)} // Handle click
            sidebar={sidebar}
          >
            <SuggestionIcon src={suggestion.icon} alt="Suggestion icon" />
            <SuggestionTitle sidebar={sidebar}>{suggestion.title}</SuggestionTitle>
            <SuggestionTags>
              {suggestion.tags.map((tag, tagIndex) => (
                <SuggestionTag key={tagIndex} sidebar={sidebar}>{tag}</SuggestionTag>
              ))}
            </SuggestionTags>
          </SuggestionCard>
        ))}
      </SuggestionsGrid>
      <ExploreButton onClick={handleNavigateExplore} sidebar={sidebar}>
        <ExploreText sidebar={sidebar}>Or See All bRAGs</ExploreText>
        <ExploreIcon
          src={rightarrow}
          alt="Explore icon"
        />
      </ExploreButton>
    </ContentContainer>
  );
};

const ContentContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 80px;
  margin-top: 70px;
  overflow-y: auto;
  flex: 1;
  display: flex;
`;

const WelcomeSection = styled.section`
  text-align: center;
  margin-bottom: 48px;
`;

const WelcomeIcon = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: 5px;
`;

const WelcomeTitle = styled.h1<{ sidebar: boolean }>`
  font-size: 23px;
  font-weight: 500;
  color: ${(props) => (props.sidebar ? "#000" : "#fff")};
  margin-bottom: 11px;
`;

const WelcomeSubtitle = styled.p<{ sidebar: boolean }>`
  font-size: 15px;
  color: ${(props) => (props.sidebar ? "#000" : "#a3a3a3")};
`;

const SuggestionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  width: 100%;
  max-width: 1280px;
  margin-bottom: 32px;
`;

const SuggestionCard = styled.div<{ sidebar: boolean }>`
  border: 1px solid ${(props) => (props.sidebar ? "#ccc" : "#333")};
  border-radius: 12px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 0.3s ease;
  justify-content: space-around;

  &:hover {
    background-color: ${(props) => (props.sidebar ? "#f0f0f0" : "#333333")}; /* Change background on hover */
  }
`;

const SuggestionIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const SuggestionTitle = styled.h3<{ sidebar: boolean }>`
  font-size: 16px;
  color: ${(props) => (props.sidebar ? "#000" : "#d4d4d4")};
  margin-bottom: 16px;
`;

const SuggestionTags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SuggestionTag = styled.span<{ sidebar: boolean }>`
  font-size: 14px;
  color: ${(props) => (props.sidebar ? "#000" : "#737373")};
  margin-right: 8px;
`;

const ExploreButton = styled.button<{ sidebar: boolean }>`
  display: flex;
  align-items: center;
  background: ${(props) => (props.sidebar ? "#000" : "#d4d4d4")};
  border: none;
  border-radius: 9999px;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (props.sidebar ? "#fff" : "#000")};
  cursor: pointer;
`;

const ExploreText = styled.span<{ sidebar: boolean }>`
  margin-right: 8px;
  color: ${(props) => (props.sidebar ? "#fff" : "#000")};
`;

const ExploreIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export default MainContent;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Logo from "./Logo";
import Navigation from "./Navigation";
import AuthButtons from "./AuthButtons";

interface HeaderProps {
  // Add any props if needed
}

const Header: React.FC<HeaderProps> = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HeaderWrapper isScrolled={isScrolled}>
      <LeftGroup>
        <Logo />
        <Navigation />
      </LeftGroup>
      <AuthButtonsWrapper>
        <AuthButtons />
      </AuthButtonsWrapper>
    </HeaderWrapper>
  );
};

interface HeaderWrapperProps {
  isScrolled: boolean;
}

const HeaderWrapper = styled.header<HeaderWrapperProps>`
  display: flex;
  align-items: center;
  width: 70%;
  margin: auto;
  margin-top: 20px;
  padding: 10px 20px;
  font-family: var(--font-family-Font-1, Inter);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: ${({ isScrolled }) => (isScrolled ? "#282828" : "transparent")};
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;
`;

const LeftGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const AuthButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto; /* Ensures it moves all the way to the right */
`;

export default Header;

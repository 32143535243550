/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import FileTreeItem from "./FileTreeItem";

interface FileTreeProps {
  // Add any props if needed
}

// const fileTreeData = [
const fileTreeData: Array<{ name: string; type: "file" | "folder"; icon: string; id: number }> = [
  {
    name: "brag",
    type: "folder",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e36fe502e19b71f2112973a0cf7930f17aab323a07c79b073e6c16355165798e?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
    id: 1
  },
  {
    name: "node_modules",
    type: "folder",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f538d72c685d3fc8c1e5764fe0bd758f325e61271847bc527ca92ddc50273942?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
    id: 2
  },
  {
    name: "public",
    type: "folder",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f538d72c685d3fc8c1e5764fe0bd758f325e61271847bc527ca92ddc50273942?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
    id: 3
  },
  {
    name: "src",
    type: "folder",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f538d72c685d3fc8c1e5764fe0bd758f325e61271847bc527ca92ddc50273942?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
    id: 4
  },
  {
    name: "tests",
    type: "folder",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f538d72c685d3fc8c1e5764fe0bd758f325e61271847bc527ca92ddc50273942?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
    id: 5
  },
  {
    name: ".gitignore",
    type: "file",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f59cd80d357fa60e43c39a68f97fc5e36d2c9612c257e05ea029644fff13888d?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
    id: 6
  },
  {
    name: "config-overrides.js",
    type: "file",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/210f9807289a902a8da6b699e0d23f7ab385a652d71bb48673a4164c9d6bc5a7?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
    id: 7
  },
  {
    name: "package-lock.json",
    type: "file",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/abbc799bf48e479fd77185cce5b5645600c0a569e8294039ad952c8f439e0f0f?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
    id: 8
  },
  {
    name: "package.json",
    type: "file",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/abbc799bf48e479fd77185cce5b5645600c0a569e8294039ad952c8f439e0f0f?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
    id: 9
  },
  {
    name: "README.md",
    type: "file",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3ca78bd00b8bd8fd41ddf68566692162ba6f488d0a78525a23e5161b01ee16f3?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
    id: 10
  },
  {
    name: "tsconfig.json",
    type: "file",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/504281dad0593384b4b48ec839d84711ebdd69ce29ab7db79e8be3e7d59b0803?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88",
    id: 11
  },
];

const FileTree: React.FC<FileTreeProps> = () => {
  return (
    <FileTreeContainer>
      <FileTreeContent>
        <FileTreeBackground>
          {fileTreeData.map((item) => (
            <FileTreeItem key={item.id} {...item} />
          ))}
        </FileTreeBackground>
      </FileTreeContent>
    </FileTreeContainer>
  );
};

const FileTreeContainer = styled.nav`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
`;

const FileTreeContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-start;
  flex: 1;
`;

const FileTreeBackground = styled.ul`
  background-color: #0a0a0a;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export default FileTree;

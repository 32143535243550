import React from "react";
import styled from "styled-components";

interface DiscardButtonProps {
  onClick?: () => void;
}

export const DiscardButton: React.FC<DiscardButtonProps> = ({ onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <ButtonIcon
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/2215a506d19a51b3873f08c44a2deb077e23a1b334b63eca8adf063cf4005dde?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88"
        alt=""
      />
      <ButtonText>Discard</ButtonText>
    </StyledButton>
  );
};

const StyledButton = styled.button`
  min-width: 80px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #d4d4d4;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  align-self: start;
  display: flex;
  min-height: 40px;
  gap: 8px;
  overflow: hidden;
  color: #000;
  text-align: center;
  padding: 10px 12px;
  font: 500 14px/20px Geist, sans-serif;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    white-space: normal;
  }
`;

const ButtonIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 18px;
  align-self: stretch;
  margin: auto 0;
`;

const ButtonText = styled.span`
  align-self: stretch;
  margin: auto 0;
`;

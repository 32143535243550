import React from "react";
import styled from "styled-components";

interface HeaderProps { }

const Header: React.FC<HeaderProps> = () => {
  return (
    <HeaderContainer>
      <AvatarCircle>T</AvatarCircle>
    </HeaderContainer>
  );
};

// Main Header container
const HeaderContainer = styled.header`
  display: flex;
  justify-content: right;
  padding: 30px 30px 0px;
  `;

const AvatarCircle = styled.div`
  width: 40px; /* Slightly larger */
  height: 40px;
  border-radius: 50%;
  background-color: #3a8cff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  cursor: default;
  position: relative;
`;

export default Header;

import React, { useState } from "react";
import styled from "styled-components";
import SidebarDark from "src/components/SidebarDark";
import SidebarLight from "src/components/SidebarLight";
import Header from "src/components/CreateInterface/Header";
import GitRepositoryList from "src/components/ImportGitRepository/GitRepositoryList";
import ThirdPartyGitImport from "src/components/ImportGitRepository/ThirdPartyGitImport";

interface GitInterfaceProps {
  sidebar: boolean;
 }

const GitInterface: React.FC<GitInterfaceProps> = ({ sidebar }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State to track sidebar visibility

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState); // Toggle sidebar state
  };

  return (
    <ChatLayout>
      <MainContainer>
        {sidebar ? (
          <SidebarLight isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        ) : (
          <SidebarDark isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        )}
        <ContentArea>
          <Header />
          <ContentContainer>
            <header className="header">
              <h1 className="title">Let's build something new.</h1>
              <p className="subtitle">
                To deploy a new Git bRAG, import an existing Git Repository.
              </p>
            </header>
            <section className="content-wrapper">
              <GitRepositoryList />
              <ThirdPartyGitImport />
            </section>
          </ContentContainer>
        </ContentArea>
      </MainContainer>
    </ChatLayout>
  );
};

const ChatLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  // background: #0d1116;
  background: #0a0a0a;
`;

const MainContainer = styled.main`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const ContentContainer = styled.main`
  overflow-y: auto;
  padding: 0px 80px 0px 80px;

  .header {
    flex-direction: column;
    max-width: 1176px;
    width: 100%;

    @media (max-width: 991px) {
      padding-bottom: 100px;
    }
  }

  .title {
    color: #ededed;
    letter-spacing: -0.93px;
    // font: 600 39px/56px Geist, sans-serif;

    @media (max-width: 991px) {
      max-width: 100%;
    }
  }

  .subtitle {
    color: #a1a1a1;
    align-self: flex-start;
    margin-top: 7px;
    font: 400 16px/24px Geist, sans-serif;

    @media (max-width: 991px) {
      max-width: 100%;
    }
  }

  .content-wrapper {
    display: flex;
    gap: 20px;
    // margin: 50px 50px 80px 50px;
    margin: 40px 0 10px;

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
      max-width: 100%;
      margin: 40px 0 10px;
    }
  }
`;

const ContentArea = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

export default GitInterface;

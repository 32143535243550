import React, { useState } from "react";
import styled from "styled-components";
import SidebarDark from "src/components/SidebarDark";
import SidebarLight from "src/components/SidebarLight";
import ChatInput from "../components/ChatInterface/ChatInput";
import { getGithubRAGResponse } from "../utils/api/lang-github";
import Header from "../components/ChatInterface/Header";
import ExplorerView from "src/components/ImportGitRepository/ExplorerView/ExplorerView";
import GitMainContent from "src/components/ImportGitRepository/GitMainContent";

interface GitChatInterfaceProps {
  sidebar: boolean;
}

const GitChatInterface: React.FC<GitChatInterfaceProps> = ({ sidebar }) => {
  const [messages, setMessages] = useState<{ user: string; bot: string }[]>([]);
  const [hasStartedChat, setHasStartedChat] = useState(false);
  const [loading, setLoading] = useState(false);

  const knowledgeSource = "GitHub";

  const [isFolderDropdownOpen, setIsFolderDropdownOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State to track sidebar visibility

  const toggleFolderDropdown = () => {
    setIsFolderDropdownOpen((prevState) => !prevState);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState); // Toggle sidebar state
  };

  const handleSendMessage = async (userMessage: string) => {
    setHasStartedChat(true);
    setLoading(true);

    const newMessage = { user: userMessage, bot: "" };
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    // const botResponse = await getRAGResponse(userMessage);
    const botResponse = await getGithubRAGResponse(userMessage);

    setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        { user: userMessage, bot: botResponse },
      ]);
      setLoading(false);
    }, 1000);
  };

  return (
    <ChatLayout>
      <MainContainer>
        {sidebar ? (
          <SidebarLight isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        ) : (
          <SidebarDark isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        )}
        <ContentArea>
          <Header
            sidebar={sidebar}
            knowledgeSource={knowledgeSource}
            isFolderDropdownOpen={isFolderDropdownOpen}
            toggleFolderDropdown={toggleFolderDropdown}
          />
          <ScrollableChatArea>
            <GitMainContent
              sidebar={sidebar}
              hasStartedChat={hasStartedChat}
              messages={messages}
              loading={loading}
              onSendMessage={handleSendMessage}
              knowledgeSource={knowledgeSource}
              toggleFolderDropdown={toggleFolderDropdown}
              isOpen={isSidebarOpen}
            />
            <ExplorerView />
          </ScrollableChatArea>
          <ChatInputWrapper>
            <ChatInput
              sidebar={sidebar}
              onSendMessage={handleSendMessage}
              loading={loading}
              knowledgeSource={knowledgeSource}
            />
          </ChatInputWrapper>
        </ContentArea>
      </MainContainer>
    </ChatLayout>
  );
};

const ChatLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #0a0a0a;
  overflow: hidden;
  position: fixed;
`;

const ChatInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(100% - 508px); /* Subtract width of sidebar (254px) and ExplorerView (254px) */
  // height: 100px;
  margin-left: 170px; /* Offset by width of sidebar */
`;

const MainContainer = styled.main`
  display: flex;
  flex-direction: row; /* Change to row to align children side-by-side */
  flex: 1;
  overflow: hidden;
`;

const ContentArea = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  width: calc(100% - 254px); /* Adjust width to account for ExplorerView */
`;

const ScrollableChatArea = styled.div`
  flex: 1;
  overflow-y: auto; /* This will make the chat messages scrollable */
  display: flex;
  // flex-direction: column;
  flex-direction: row;
  max-height: calc(100vh - 170px); /* Adjust this value based on your layout */

`;

export default GitChatInterface;

import React from "react";
import styled from "styled-components";

interface NavProgressItemProps {
  icon?: string;
  title: string;
  description: string;
  step: number;
}

export const NavProgressItem: React.FC<NavProgressItemProps> = ({
  icon,
  title,
  description,
  step,
}) => {
  return (
    <ItemWrapper step={step}>
      <IconWrapper>
        {icon ? (
          <IconBackground>
            <Icon loading="lazy" src={icon} alt="" />
          </IconBackground>
        ) : (
          <StepCircle>{step}</StepCircle>
        )}
      </IconWrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </ContentWrapper>
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div<{ step: number }>`
  display: flex;
  width: 100%;
  max-width: 278px;
  align-items: center;
  font-family: Geist, sans-serif;
  justify-content: start;  // Align items to the start
  margin-top: ${(props) => (props.step === 1 ? "0" : "12px")};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;  // Center the step number vertically
  width: 34px;
  height: 34px;
  margin-right: 20px;  // Add margin to separate from the content
`;

const IconBackground = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  display: flex;
  min-height: 34px;
  // width: 34px;
  overflow: hidden;
  height: 34px;
  padding: 0 5px;
`;

const Icon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
`;

const StepCircle = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  display: flex;
  min-height: 34px;
  width: 34px;
  height: 34px;
  padding: 0 12px;
  font-size: 16px;
  color: #d4d4d8;
  font-weight: 600;
  text-align: center;
  line-height: 24px;
`;

const ContentWrapper = styled.div`
  // display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  flex-basis: 0%;
`;

const Title = styled.h3`
  width: 100%;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
`;

const Description = styled.p`
  width: 100%;
  font-size: 14px;
  color: #6b7280;
  font-weight: 400;
`;

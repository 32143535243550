import axios from 'axios';

interface ChatInput {
    input_value: string;
    output_type: string;
    input_type: string;
    tweaks: {
        "ChatInput-giLWL": object,
        "ParseData-OqpIG": object,
        "Prompt-DlhYr": object,
        "ChatOutput-ep1oP": object,
        "OpenAIEmbeddings-CMNPJ": object,
        "Chroma-EoidV": object,
        "GitHubFileLoaderComponent-rzesU": object,
        "Directory-OKMSp": object,
        "Chroma-3qxPv": object,
        "OpenAIEmbeddings-ZKlyZ": object,
        "SplitText-oUZzd": object,
        "MistralModel-qUusG": object,
        "TextInput-se5w0": object
    };
}

export const getGithubRAGResponse = async (message: string): Promise<string> => {
    const url = "http://127.0.0.1:7860/api/v1/run/2d9f55be-6f94-4788-ba8b-1cd6d7119f55?stream=false";

    const headers = {
        'Content-Type': 'application/json',
    };

    const data: ChatInput = {
        input_value: message,
        output_type: "chat",
        input_type: "chat",
        tweaks: {
            "ChatInput-giLWL": {},
            "ParseData-OqpIG": {},
            "Prompt-DlhYr": {},
            "ChatOutput-ep1oP": {},
            "OpenAIEmbeddings-CMNPJ": {},
            "Chroma-EoidV": {},
            "GitHubFileLoaderComponent-rzesU": {},
            "Directory-OKMSp": {},
            "Chroma-3qxPv": {},
            "OpenAIEmbeddings-ZKlyZ": {},
            "SplitText-oUZzd": {},
            "MistralModel-qUusG": {},
            "TextInput-se5w0": {},
        }
    };

    try {
        const response = await axios.post(url, data, { headers });

        // Extract the final message from the response
        const finalMessage = response.data.outputs[0].outputs[0].results.message.text;

        // Return the actual final message
        return finalMessage;

    } catch (error) {
        console.error('Error:', error);
        return 'Error retrieving the message.';
    }
};

/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import SearchBox from "./SearchBox";
import RepositoryItem from "./RepositoryItem";

interface GitRepositoryListProps {
  // Add any props if needed
}

const GitRepositoryList: React.FC<GitRepositoryListProps> = () => {
  const repositories = [
    { name: "bRAG", lastUpdated: "53m ago" },
    { name: "bRAG", lastUpdated: "53m ago" },
    { name: "bRAG", lastUpdated: "53m ago" },
    { name: "bRAG", lastUpdated: "53m ago" },
    { name: "bRAG", lastUpdated: "53m ago" },
  ];

  return (
    <StyledSection>
      <h2 className="section-title">Import Git Repository</h2>
      <SearchBox />
      <ul className="repository-list">
        {repositories.map((repo, index) => (
          <RepositoryItem
            key={index}
            name={repo.name}
            lastUpdated={repo.lastUpdated}
          />
        ))}
      </ul>
      <a href="#" className="import-link">
        Import OpenAPI Swagger Schema →
      </a>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  min-width: 1px;
  max-width: 1176px;
  border-radius: 12px;
  border: 1px solid #333;
  background-color: #0a0a0a;
  box-shadow: 0px 8px 16px -4px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-start;
  width: 58%;
  gap: 24px;
  padding: 33px;

  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    margin-top: 26px;
    padding: 0 20px;
  }

  .section-title {
    color: #ededed;
    letter-spacing: -0.47px;
    font: 600 24px/36px Geist, sans-serif;
  }

  .repository-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .import-link {
    color: #999;
    margin-top: 24px;
    font: 500 14px/22.4px Geist, sans-serif;
    text-decoration: none;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
`;

export default GitRepositoryList;

import React from "react";
import styled from "styled-components";

interface AuthButtonsProps {
  // Add any props if needed
}

const AuthButtons: React.FC<AuthButtonsProps> = () => {
  const handleNavigate = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }); // This will scroll to the bottom of the page
  };

  return (
    <ButtonGroup>
      <LoginButton onClick={handleNavigate}>Login</LoginButton>
      <SignUpButton onClick={handleNavigate}>Sign Up</SignUpButton>
    </ButtonGroup>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  font-size: 14px;
  color: var(--color-white-solid, #fff);
  line-height: var(--line-height-20, 20px);
`;

const LoginButton = styled.button`
  align-self: stretch;
  border-radius: 6px;
  font-weight: var(--font-weight-550, 550);
  white-space: nowrap;
  padding: 9px 17px;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out; /* Optional: Smooth transition */

  &:hover {
    background-color: #333; /* Background color on hover */
  }

  @media (max-width: 991px) {
    white-space: initial;
  }

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const SignUpButton = styled.button`
  align-self: start;
  border-radius: 6px;
  background-color: rgba(23, 23, 23, 1);
  font-weight: var(--font-weight-400, 400);
  padding: 8px 16px;
  border: none;
  color: inherit;
  cursor: pointer;
`;

export default AuthButtons;

import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface NavigationProps {
  // Add any props if needed
}

const Navigation: React.FC<NavigationProps> = () => {
  // const navigate = useNavigate();
  
  const handleNavigate = () => {
    // navigate('/chat');
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }); // This will scroll to the bottom of the page
  };

  return (
    <Nav>
      <NavItem onClick={handleNavigate}>Chat</NavItem>
      <NavItem onClick={handleNavigate}>Explore bRAG</NavItem>
      <NavItem onClick={handleNavigate}>Blog</NavItem>
    </Nav>
  );
};

const Nav = styled.nav`
  display: flex;
  gap: 5px;
  font-size: 14px;
  color: var(--brag-ai-alto, var(--color-grey-83, #d4d4d4));
  font-weight: var(--font-weight-400, 400);
  line-height: var(--line-height-15_4, 15.4px);
`;

const NavItem = styled.a`
  align-self: stretch;
  border-radius: 6px;
  white-space: nowrap;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out; /* Optional: Smooth transition */

  &:hover {
    background-color: #333; /* Background color on hover */
  }

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export default Navigation;

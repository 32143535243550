import React from "react";
import styled from "styled-components";

interface RepositoryItemProps {
  name: string;
  lastUpdated: string;
}

const RepositoryItem: React.FC<RepositoryItemProps> = ({
  name,
  lastUpdated,
}) => {
  return (
    <StyledListItem>
      <div className="repo-info">
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/54acb620a20a6eb5d009a2eb8a4fe14b5ea0836c8002f7d28c2ba39bf47a6f45?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88"
          alt=""
          className="repo-icon"
        />
        <span className="repo-name">{name}</span>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/919747158c878f4e3eecc572c58c613408a715602777c4cd00205f59dcf228be?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88"
          alt=""
          className="repo-status"
        />
        <span className="repo-separator">·</span>
        <span className="repo-time">{lastUpdated}</span>
      </div>
      <button className="import-button">Import</button>
    </StyledListItem>
  );
};

const StyledListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #2e2e2e;
  background-color: #0a0a0a;
  padding: 17px;
  margin-bottom: 16px;

  @media (max-width: 991px) {
    max-width: 100%;
  }

  .repo-info {
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 14px;
    color: #888;
    font-weight: 400;
    line-height: 24px;
  }

  .repo-icon {
    width: 36px;
    height: 24px;
  }

  .repo-name {
    color: #ededed;
    font-weight: 500;
  }

  .repo-status {
    width: 14px;
    height: 14px;
  }

  .repo-separator {
    margin: 0 3px;
  }

  .import-button {
    border-radius: 6px;
    background-color: #ededed;
    color: #0a0a0a;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    padding: 6px 12px;
    border: none;
    cursor: pointer;
  }
`;

export default RepositoryItem;

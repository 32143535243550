import React from "react";
import styled from "styled-components";

interface FeatureCardMobileProps {
  title: string;
  description: string;
  imageSrc: string;
  isReverse?: boolean; // Added for alternating layout
}

const FeatureCardMobile: React.FC<FeatureCardMobileProps> = ({
  title,
  description,
  imageSrc,
  isReverse = false,
}) => {
  return (
    <Card isReverse={isReverse}>
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
      <Image src={imageSrc} alt={title} loading="lazy" />
    </Card>
  );
};

const Card = styled.div<{ isReverse: boolean }>`
  display: flex;
  flex-direction: ${({ isReverse }) => (isReverse ? "row-reverse" : "row")};
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 25px;
  font-family: var(--font-family-Font-1, Inter);
  gap: 20px;
  width: 100%;
  max-width: 1120px;
  margin: auto;

  @media (max-width: 991px) {
    flex-direction: column;
    padding: 20px;
    gap: 15px;
  }
`;

const TextContainer = styled.div`
  flex: 1;
`;

const Title = styled.h3`
  color: var(--brag-ai-white, var(--color-white-solid, #fff));
  font-size: var(--line-height-20, 20px);
  font-weight: var(--font-weight-700, 700);
  line-height: var(--line-height-28, 28px);
`;

const Description = styled.p`
  margin-top: 8px;
  font-size: 14px;
  color: var(--brag-ai-alto, var(--color-grey-83, #d4d4d4));
  font-weight: var(--font-weight-400, 400);
  line-height: var(--line-height-20, 20px);
  max-width: 400px;

  @media (max-width: 991px) {
    max-width: 100%;
    text-align: left;
  }
`;

const Image = styled.img`
  flex: 1;
  border-radius: 12px;
  object-fit: contain;
  object-position: center;
  width: 100%;
  max-width: 500px;
  aspect-ratio: 1.75;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default FeatureCardMobile;

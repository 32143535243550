import axios from 'axios';

interface ChatInput {
  input_value: string;
  output_type: string;
  input_type: string;
  tweaks: {
    "ChatInput-7MAkr": object;
    "ParseData-TRmQR": object;
    "Prompt-RxviR": object;
    "ChatOutput-aJRqL": object;
    "SplitText-N07Fh": object;
    "File-IdaEX": object;
    "OpenAIEmbeddings-po2yb": object;
    "OpenAIEmbeddings-92tKC": object;
    "Memory-wRlFC": object;
    "AstraDBChatMemory-JHazi": object;
    "Memory-yFqbg": object;
    "ParseData-p0UUD": object;
    "OpenAIModel-vsZK1": object;
    "MistralModel-EDGc7": object;
    "Pinecone-pLhRr": object;
    "Pinecone-F5Ch6": object;
  };
}

export const getRAGResponse = async (message: string): Promise<string> => {
  const url = "https://langflow.tahaababou.com/api/v1/run/bragai?stream=false";
  const headers = {
    'Content-Type': 'application/json',
  };

  const data: ChatInput = {
    input_value: message,
    output_type: "chat",
    input_type: "chat",
    tweaks: {
      "ChatInput-7MAkr": {},
      "ParseData-TRmQR": {},
      "Prompt-RxviR": {},
      "ChatOutput-aJRqL": {},
      "SplitText-N07Fh": {},
      "File-IdaEX": {},
      "OpenAIEmbeddings-po2yb": {},
      "OpenAIEmbeddings-92tKC": {},
      "Memory-wRlFC": {},
      "AstraDBChatMemory-JHazi": {},
      "Memory-yFqbg": {},
      "ParseData-p0UUD": {},
      "OpenAIModel-vsZK1": {},
      "MistralModel-EDGc7": {},
      "Pinecone-pLhRr": {},
      "Pinecone-F5Ch6": {}
    }
  };

  try {
    const response = await axios.post(url, data, { headers });

    // Extract the final message from the response
    const finalMessage = response.data.outputs[0].outputs[0].results.message.text;

    // Return the actual final message
    return finalMessage;

  } catch (error) {
    console.error('Error:', error);
    return 'Error retrieving the message.';
  }
};
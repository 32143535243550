/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

interface SearchBoxProps {
  // Add any props if needed
}

const SearchBox: React.FC<SearchBoxProps> = () => {
  return (
    <StyledSearchBox>
      <div className="namespace-select">
        <span className="selected-namespace">tahababou12</span>
        <button className="open-menu-button" aria-label="Open menu">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/bff306e76e04be6e778a75f198f821873dc97e0e3fd35ad64703c888e00a263d?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88"
            alt=""
          />
        </button>
        <img
          className="namespace-icon"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/45114bc42762912ec7921d92e9a83ee0b7caaca94cfc9a3d21480f6aae8338c9?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88"
          alt=""
        />
      </div>
      <div className="search-input">
        <label htmlFor="search-repo" className="visually-hidden">
          Search repositories
        </label>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/34b43ed15068e14491893835eddd1a4dd2f900b622ccc9495d5312733709729e?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88"
          alt=""
          className="search-icon"
        />
        <input type="text" id="search-repo" placeholder="Search..." />
      </div>
    </StyledSearchBox>
  );
};

const StyledSearchBox = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;

  @media (max-width: 991px) {
    max-width: 100%;
  }

  .namespace-select {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    background-color: #0a0a0a;
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.14);
    min-width: 240px;
    min-height: 40px;
    padding: 11.25px 40px;
    color: #ededed;
    font: 400 14px Geist, sans-serif;
    flex: 1;
    flex-basis: 0%;

    @media (max-width: 991px) {
      padding: 11.25px 20px;
    }
  }

  .selected-namespace {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .open-menu-button {
    position: absolute;
    right: 12px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  .namespace-icon {
    position: absolute;
    left: 20px;
    width: 14px;
    height: 14px;
  }

  .search-input {
    display: flex;
    align-items: center;
    border-radius: 6px;
    background-color: #0a0a0a;
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.14);
    flex: 1;
    flex-basis: 0%;
    padding: 10px 12px;
  }

  .search-icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  input {
    background: transparent;
    border: none;
    color: #8f8f8f;
    font: 400 13px Geist, sans-serif;
    width: 100%;

    &::placeholder {
      color: #8f8f8f;
    }

    &:focus {
      outline: none;
    }
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`;

export default SearchBox;

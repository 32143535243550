import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --font-family-Font-1: Geist, sans-serif;
    --color-white-solid: #fff;
    --color-black-solid: #000;
    --color-grey-93: #ecedee;
    --color-grey-90: #e5e5e5;
    --color-grey-83: #d4d4d4;
    --color-grey-64: #a3a3a3;
    --color-grey-15: #262626;
    --color-grey-9: #171717;
    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-550: 550;
    --font-weight-600: 600;
    --font-weight-700: 700;
    --font-size-14: 14px;
    --font-size-16: 16px;
    --font-size-30: 30px;
    --font-size-48: 48px;
    --line-height-15_4: 15.4px;
    --line-height-16: 16px;
    --line-height-20: 20px;
    --line-height-24: 24px;
    --line-height-28: 28px;
    --line-height-36: 36px;
    --line-height-96: 96px;
    --letter-spacing--0_72: -0.72px;
    --letter-spacing--0_75: -0.75px;
    --item-spacing-8_01: 8.01px;
    --item-spacing-s: 24px;
    --width-384: 384px;
    --stroke-weight-1: 1px;
  }

  /* Custom Scrollbar Styles */
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #0a0a0a; /* Match your dark background */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #111; /* Darker color for thumb */
    border-radius: 10px;
    border: 3px solid #0a0a0a; /* Add padding around the thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #616161; /* Change color on hover */
  }

  body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family-Font-1);
    background-color: #000;
    color: #fff;
  }

  * {
    box-sizing: border-box;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }
`;

export default GlobalStyles;

import React, { useState } from "react";
import styled from "styled-components";
import SidebarDark from "src/components/SidebarDark";
import SidebarLight from "src/components/SidebarLight";
import MainContent from "../components/ChatInterface/MainContent";
import ChatInput from "../components/ChatInterface/ChatInput";
import { getRAGResponse } from "../utils/api/lang";
import Header from "../components/ChatInterface/Header";
import SourcesSidebar from "src/components/ChatInterface/SourcesSidebar";

interface ChatInterfaceProps {
  sidebar: boolean;
  sidebarSource: boolean;
 }

const ChatInterface: React.FC<ChatInterfaceProps> = ({ sidebar, sidebarSource }) => {
  const [messages, setMessages] = useState<{ user: string; bot: string }[]>([]);
  const [hasStartedChat, setHasStartedChat] = useState(false);
  const [loading, setLoading] = useState(false);

  const knowledgeSource = "Everything About Taha Ababou's Career";

  const [isFolderDropdownOpen, setIsFolderDropdownOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State to track sidebar visibility
  const [isSidebarSourceOpen, setIsSidebarSourceOpen] = useState(true); // State to track sidebar visibility

  const toggleFolderDropdown = () => {
    setIsFolderDropdownOpen((prevState) => !prevState);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState); // Toggle sidebar state
  };
  
  const toggleSidebarSource = () => {
    setIsSidebarSourceOpen((prevState) => !prevState); // Toggle sidebar state
  };

  const handleSendMessage = async (userMessage: string) => {
    setHasStartedChat(true);
    setLoading(true);

    const newMessage = { user: userMessage, bot: "" };
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    const botResponse = await getRAGResponse(userMessage);

    setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        { user: userMessage, bot: botResponse },
      ]);
      setLoading(false);
    }, 1000);
  };

  return (
    <ChatLayout sidebar={sidebar}>
      <MainContainer>
        {sidebar ? (
          <SidebarLight isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        ) : (
          <SidebarDark isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        )}
        <ContentArea>
          <Header
            sidebar={sidebar}
            knowledgeSource={knowledgeSource}
            isFolderDropdownOpen={isFolderDropdownOpen}
            toggleFolderDropdown={toggleFolderDropdown}
          />
          <ScrollableChatArea>
            <MainContent
              hasStartedChat={hasStartedChat}
              messages={messages}
              loading={loading}
              onSendMessage={handleSendMessage}
              knowledgeSource={knowledgeSource}
              toggleFolderDropdown={toggleFolderDropdown}
              isOpen={isSidebarOpen}
              sidebar={sidebar}
            />
          </ScrollableChatArea>
          <ChatInput
            sidebar={sidebar}
            onSendMessage={handleSendMessage}
            loading={loading}
            knowledgeSource={knowledgeSource}
          />
        </ContentArea>
        {sidebar ? (
          <SourcesSidebar isOpen={isSidebarSourceOpen} toggleSidebar={toggleSidebarSource} />
        ) : (
          <SourcesSidebar isOpen={isSidebarSourceOpen} toggleSidebar={toggleSidebarSource} />
        )}
      </MainContainer>
    </ChatLayout>
  );
};

const ChatLayout = styled.div<{ sidebar: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: ${(props) => (props.sidebar ? "#fafafa" : "#0a0a0a")};
  overflow: hidden;
  position: fixed;
`;

const MainContainer = styled.main`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const ContentArea = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const ScrollableChatArea = styled.div`
  flex: 1;
  overflow-y: auto; /* This will make the chat messages scrollable */
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 170px); /* Adjust this value based on your layout */

`;

export default ChatInterface;

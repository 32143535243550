/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

interface FileTreeItemProps {
  name: string;
  type: "file" | "folder";
  icon: string;
}

const FileTreeItem: React.FC<FileTreeItemProps> = ({ name, type, icon }) => {
  return (
    <FileTreeItemContainer>
      <FileTreeItemContent>
        <IconWrapper>
          <Icon src={icon} alt="" />
        </IconWrapper>
        <ItemName>{name}</ItemName>
      </FileTreeItemContent>
    </FileTreeItemContainer>
  );
};

const FileTreeItemContainer = styled.li`
  display: flex;
  min-height: 22px;
  width: 100%;
  padding-left: 4px;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
`;

const FileTreeItemContent = styled.div`
  display: flex;
  width: 100%;
  padding-left: 3px;
  align-items: center;
  gap: -3px;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
`;

const IconWrapper = styled.div`
  align-self: stretch;
  display: flex;
  min-height: 22px;
  align-items: center;
  justify-content: center;
  width: 30px;
  margin: auto 0;
  padding: 3px 6px 3px 8px;
`;

const Icon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
`;

const ItemName = styled.span`
  align-self: stretch;
  display: flex;
  min-height: 22px;
  flex-direction: column;
  overflow: hidden;
  color: #ccc;
  white-space: nowrap;
  justify-content: flex-start;
  flex: 1;
  flex-basis: 14px;
  margin: auto 0;
  font: 400 14px/22px "SF Pro Text", sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export default FileTreeItem;

import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import PreviewBanner from "./PreviewBanner";
import { useNavigate } from "react-router-dom";
import WaitListForm from "./WaitListForm";

interface HeroProps {
  // Add any props if needed
}

const Hero: React.FC<HeroProps> = () => {
  const navigate = useNavigate();
  const subtitles = ["  Train It With Your Data.", " Chat With Your Code.", " Interact with YouTube Videos.", " Create Your Personal Avatar.", " Share It To The World."];

  const [currentText, setCurrentText] = useState(""); // For displaying typed text
  const [currentTextIndex, setCurrentTextIndex] = useState(0); // For cycling through subtitles
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    let charIndex = 0;
    let timeoutId: NodeJS.Timeout;

    // Function to simulate typing effect
    const typeText = () => {
      if (charIndex < subtitles[currentTextIndex].length) {
        setCurrentText((prev) => prev + subtitles[currentTextIndex].charAt(charIndex));
        charIndex++;
        timeoutId = setTimeout(typeText, 100); // Adjust typing speed here (100ms per character)
      } else {
        // After typing is complete, wait for 2 seconds and then fade out
        setTimeout(() => {
          setIsFading(true);
        }, 2000); // Time before fading out
      }
    };

    // Start typing
    setCurrentText(""); // Clear previous text
    setIsFading(false); // Reset fading state
    typeText();

    return () => clearTimeout(timeoutId); // Cleanup timeout
  }, [currentTextIndex]);

  useEffect(() => {
    if (isFading) {
      // Fade out and change the text after 1 second
      const fadeTimeout = setTimeout(() => {
        setIsFading(false);
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % subtitles.length); // Move to the next subtitle
      }, 1000); // Duration of the fade-out

      return () => clearTimeout(fadeTimeout);
    }
  }, [isFading]);

  return (
    <HeroSection>
      <HeroTitle>Design Your AI</HeroTitle>
      <HeroSubtitle isFading={isFading}>{currentText}</HeroSubtitle>
      <HeroDescription>
        Create your AI avatar or chat with files, powered by{" "}
        <br />
        <span className="bold">GPT-4o</span> for accurate, real-time answers
      </HeroDescription>
      {/* <ChatButton placeholder="Chat with bRAG-preview-01"/> */}
      <WaitListForm placeholder="Enter your email" buttonText="Join Waitlist" />
      {/* <PreviewBanner label="Try bRAG-preview-01, our first context-switching model" /> */}
      <PreviewBanner label="bRAG-preview-01 will be released on January 5th, 2025" />
    </HeroSection>
  );
};

// Keyframes for fading out
const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

// Styled components
const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 225px;
`;

const HeroTitle = styled.h1`
  color: var(--brag-ai-porcelain, var(--color-grey-93, #ecedee));
  margin-top: 30px;
  font: var(--font-weight-400, 400) 90px / var(--line-height-96, 96px)
    var(--font-family-Font-1, Inter);
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

const HeroSubtitle = styled.h2<{ isFading: boolean }>`
  color: var(--brag-ai-porcelain, var(--color-grey-93, #ecedee));
  margin-top: 0px;
  font: var(--font-weight-400, 400) var(--font-size-48, 48px) /
    var(--font-size-48, 48px) var(--font-family-Font-1, Inter);
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
  height: 50px;

  ${({ isFading }) =>
    isFading &&
    css`
      animation: ${fadeOut} 1s ease-in-out forwards;
    `}
`;

const HeroDescription = styled.p`
  color: var(--brag-ai-alto, var(--color-grey-83, #d4d4d4));
  margin-top: 10px;
  font: 400 19px / var(--line-height-28, 28px) var(--font-family-Font-1, Inter);
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 20px;
  }

  .bold {
    font-weight: 700;
  }
`;

export default Hero;

import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FiCopy } from 'react-icons/fi';
import remarkGfm from 'remark-gfm';
import linkifyIt from 'linkify-it';
import { BlockMath, InlineMath } from 'react-katex'; // Import KaTeX components
import 'katex/dist/katex.min.css'; // Import KaTeX styles

// Icons
import logo from "@/assets/img/brag-w.png";
import logoDark from "@/assets/img/brag-d.png";

const linkify = new linkifyIt(); // Initialize the linkify-it instance

interface ChatMessagesProps {
  messages: { user: string; bot: string }[];
  loading: boolean;
  isOpen: boolean;
  sidebar: boolean; // Add sidebar prop to determine the theme
}

const ChatMessages: React.FC<ChatMessagesProps> = ({ messages, loading, isOpen, sidebar }) => {
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);
  const [displayedMessages, setDisplayedMessages] = useState<string[]>([]);
  const [processedIndices, setProcessedIndices] = useState<number[]>([]);
  const bottomRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   setDisplayedMessages((prev) =>
  //     messages.map((msg, index) => prev[index] || "")
  //   );
  // }, [messages]);

  useEffect(() => {
    setDisplayedMessages(messages.map(message => message.bot || ""));
    setProcessedIndices(messages.map((_, index) => index)); // Mark all indices as processed
  }, [messages]);
  

  // useEffect(() => {
  //   messages.forEach((message, index) => {
  //     if (message.bot && !processedIndices.includes(index)) {
  //       typeWriterEffect(message.bot, index);
  //       setProcessedIndices((prev) => [...prev, index]);
  //     }
  //   });

  //   // bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  // }, [messages, processedIndices]);

  // const typeWriterEffect = (text: string, index: number) => {
  //   let isWordBased = text.length > 100;
  //   let parts = isWordBased ? text.split(" ") : text.split("");
  //   let intervalTime = isWordBased ? 100 : 50;

  //   let displayText = "";
  //   let i = 0;

  //   const interval = setInterval(() => {
  //     if (i < parts.length) {
  //       displayText += isWordBased ? parts[i] + " " : parts[i];
  //       setDisplayedMessages((prev) =>
  //         prev.map((msg, idx) => (idx === index ? displayText : msg))
  //       );
  //       i++;
  //     } else {
  //       clearInterval(interval);
  //     }
  //   }, intervalTime);
  // };

  const handleCopy = (content: string, index: number) => {
    navigator.clipboard.writeText(content);
    setCopiedIndex(index);

    setTimeout(() => {
      setCopiedIndex(null);
    }, 2500);
  };

  const linkifyText = (text: string) => {
    const matches = linkify.match(text); // Find all links in the text

    if (!matches) return text;

    let result = "";
    let lastIndex = 0;

    matches.forEach((match) => {
      const start = match.index;
      const end = match.lastIndex;

      result += text.slice(lastIndex, start);
      result += `[${match.text}](${match.url})`; // Convert to Markdown link format
      lastIndex = end;
    });

    result += text.slice(lastIndex); // Add remaining text
    return result;
  };

  const detectMathEquations = (text: string) => {
    // Regex for detecting block equations $$...$$ and inline equations $...$
    const blockEquationPattern = /\$\$([^$]+)\$\$/g;
    const inlineEquationPattern = /\$([^$]+)\$/g;

    // First, split the text into block-level math and other text
    const parts = text.split(blockEquationPattern).map((part, index) => {
      if (index % 2 === 1) {
        // This is block-level math
        return <BlockMath key={index}>{part}</BlockMath>;
      }
      // For inline equations within normal text
      return part.split(inlineEquationPattern).map((subPart, subIndex) => {
        if (subIndex % 2 === 1) {
          // This is inline math
          return <InlineMath key={subIndex}>{subPart}</InlineMath>;
        }
        return subPart;
      });
    });

    return parts;
  };

  const renderTable = (props: any) => {
    return (
      <TableWrapper>
        <table {...props} />
      </TableWrapper>
    );
  };

  return (
    <MessagesContainer isOpen={isOpen} sidebar={sidebar}>
      {messages.map((message, index) => (
        <MessageWrapper key={index}>
          <UserMessageContainer>
            <UserMessage sidebar={sidebar}>{message.user}</UserMessage>
          </UserMessageContainer>

          {message.bot && (
            <BotMessageContainer>
              <BotAvatar sidebar={sidebar}>
                <img src={sidebar ? logoDark : logo} style={{ width: '18px', height: '18px' }} alt="Bot Avatar" />
              </BotAvatar>
              <BotMessage sidebar={sidebar}>
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    p: ({ children }) => (
                      <p>
                        {Array.isArray(children)
                          ? children.map((child, i) => (
                            <React.Fragment key={i}>
                              {typeof child === 'string'
                                ? detectMathEquations(child) // Only process strings for math
                                : child                      // Render elements as they are
                              }
                            </React.Fragment>
                          ))
                          : typeof children === 'string'
                            ? detectMathEquations(children)   // Process strings outside of array
                            : children                        // Render non-string content as is
                        }
                      </p>
                    ),
                    a: ({ href, children, ...props }) => (
                      <a
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#61dafb' }}
                        {...props}
                      >
                        {children}
                      </a>
                    ),
                    code({ node, className, children, ...props }) {
                      const match = /language-(\w+)/.exec(className || '');
                      const codeContent = String(children).replace(/\n$/, '');

                      return match ? (
                        <CodeBlockWrapper>
                          <CodeHeader>
                            <LanguageTag>{match[1].toUpperCase()}</LanguageTag>
                            <CopyButton onClick={() => handleCopy(codeContent, index)}>
                              {copiedIndex === index && <CopiedText>Copied</CopiedText>}
                              <FiCopy size={16} />
                            </CopyButton>
                          </CodeHeader>
                          <StyledSyntaxHighlighter
                            language={match[1]}
                            style={materialDark}
                            PreTag="div"
                          >
                            {children ? codeContent : "Code"}
                          </StyledSyntaxHighlighter>
                        </CodeBlockWrapper>
                      ) : (
                        <code className={className} {...props}>
                          {children ? children : "Inline Code"}
                        </code>
                      );
                    },
                    table: renderTable // Custom render for tables with copy button
                  }}
                >
                  {linkifyText(displayedMessages[index])}
                </ReactMarkdown>
              </BotMessage>
            </BotMessageContainer>
          )}
        </MessageWrapper>
      ))}

      {loading && (
        <LoadingWrapper>
          <LoadingSpinner sidebar={sidebar} />
        </LoadingWrapper>
      )}

      <div ref={bottomRef} />
    </MessagesContainer>
  );
};

// Container for chat messages
const MessagesContainer = styled.div<{ isOpen: boolean, sidebar: boolean }>`
  flex: 1;
  padding: 20px 0 60px 0;
  margin: ${(props) => (props.isOpen ? "0px 10%" : "0px 24%")}; //adjusting the chat container based on whether sidebar is open or not
  display: flex;
  flex-direction: column;
  gap: 16px;
  // background-color: ${(props) => (props.sidebar ? "#f0f0f0" : "#1e1e1e")}; // Change background color based on sidebar
`;

// Wrapper for each set of user/bot messages
const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

// User message styling
const UserMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0px;
  margin-left: auto;
  max-width: 70%;
`;

const UserMessage = styled.div<{ sidebar: boolean }>`
  background-color: ${(props) => (props.sidebar ? "#e0e0e0" : "#2f2f2f")}; // Change background color based on sidebar
  padding: 12px 16px;
  border-radius: 18px;
  color: ${(props) => (props.sidebar ? "#000" : "#fff")}; // Change text color based on sidebar
  font-size: 16px;
  line-height: 28px;
  font-family: system-ui;
`;

// Bot message styling
const BotMessageContainer = styled.div`
  display: flex;
  margin-left: 0;
`;

const BotMessage = styled.div<{ sidebar: boolean }>`
  padding: 12px 16px;
  border-radius: 18px;
  color: ${(props) => (props.sidebar ? "#000" : "lightgray")}; // Change text color based on sidebar
  font-size: 16px;
  max-width: 75%;
  font-family: system-ui;
  line-height: 28px;
`;

const BotAvatar = styled.div<{ sidebar: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${(props) => (props.sidebar ? "#d0d0d0" : "#3a3a3a")}; // Change background color based on sidebar
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.sidebar ? "#000" : "#fff")}; // Change text color based on sidebar
  font-weight: bold;
  margin-right: 10px;
  margin-top: 25px;
`;

// Keyframes for loading animation
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const LoadingSpinner = styled.div<{ sidebar: boolean }>`
  border: 4px solid ${(props) => (props.sidebar ? "rgba(0, 0, 0, 0.3)" : "rgba(255, 255, 255, 0.3)")};
  border-top: 4px solid ${(props) => (props.sidebar ? "#000" : "#fff")};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${spin} 1s linear infinite;
`;

// Wrapper for code block with a header and a copy button
const CodeBlockWrapper = styled.div`
  position: relative;
  padding-top: 30px; /* To make space for the header */
  border-radius: 8px;
  background-color: #1d1d1d;
`;

// Header for code block with language tag and copy button
const CodeHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  background-color: #2a2a2a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: white;
`;

// Language tag styling
const LanguageTag = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: lightgray;
`;

// Custom button for copying code or table
const CopyButton = styled.button`
  background: none;
  border: none;
  color: lightgray;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;

  &:hover {
    color: white;
  }
`;

// Text for 'Copied'
const CopiedText = styled.span`
  font-size: 11px;
  margin-right: 8px;
  color: #d3d3d3;
  font-family: Geist, monospace;
`;

// Styled SyntaxHighlighter to ensure correct positioning
const StyledSyntaxHighlighter = styled(SyntaxHighlighter)`
  padding: 16px;
  background-color: #2d2d2d;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

// Custom Table Wrapper for the copy button
const TableWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  overflow-x: auto;
  margin-top: 8px;
  margin-bottom: 16px;
  border-radius: 12px; /* Add the border radius */
  overflow: hidden; /* Ensure content stays within rounded borders */

  table {
    width: 100%;
    border-spacing: 0; /* Add border-spacing instead of border-collapse */
    background-color: #1e1e1e; /* Darker background for table */
    overflow: hidden;
  }

  th {
    padding: 10px 12px;
    background-color: #383838; /* Darker background for headers */
    color: #fff;
    text-align: left;
    border-bottom: 1px solid #444; /* Thin borders for headers */
    font-weight: bold;
  }

  td {
    padding: 10px 12px;
    background-color: #212121; /* Slightly lighter background for table rows */
    color: #ddd;
    border-bottom: 1px solid #444; /* Thin borders for table data */
    text-align: left;
  }

  tr:nth-child(even) td {
    background-color: #242424; /* Alternating background color for better readability */
  }

  tr:hover td {
    background-color: #383838; /* Highlight row on hover */
  }

  /* Fix border-radius for the first row (header) */
  tr:first-child th:first-child {
    border-top-left-radius: 12px;
  }

  tr:first-child th:last-child {
    border-top-right-radius: 12px;
  }

  /* Fix border-radius for the last row */
  tr:last-child td:first-child {
    border-bottom-left-radius: 12px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 12px;
  }

  th, td {
    border: 1px solid #444; /* Thin borders */
  }
`;

export default ChatMessages;

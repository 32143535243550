import React from "react";
import styled from "styled-components";
import logo from "@/assets/img/logo-dark.png";
import { FiBook, FiChevronLeft, FiChevronRight, FiFileText, FiGithub, FiGlobe, FiMessageCircle, FiPlusCircle, FiSearch, FiUser, FiYoutube } from 'react-icons/fi'; // Import icons for arrows
import { useNavigate } from "react-router-dom";

interface SidebarLightProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const SidebarLight: React.FC<SidebarLightProps> = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();

  const handleNavigateExplore = () => {
    navigate('/explore'); // This will navigate to the ExploreInterface page
  };

  const handleNavigateChat = () => {
    navigate('/chat'); // This will navigate to the ChatInterface page
  };

  const handleNavigateCreate = () => {
    navigate('/create'); // This will navigate to the CreateInterface page
  };

  const handleNavigateGit = () => {
    navigate('/git'); // This will navigate to the CreateInterface page
  };

  return (
    <SidebarContainer isOpen={isOpen}>
      <ToggleButton onClick={toggleSidebar} isOpen={isOpen}>
        {isOpen ? <FiChevronLeft /> : <FiChevronRight />} {/* Arrow changes based on state */}
      </ToggleButton>
      {isOpen && (
        <>
          <Logo>
            <LogoImage
              src={logo}
              alt="bRAG AI Logo"
            />
            <LogoText href="/">
              bRAG
              <AILink>
                AI
              </AILink>
            </LogoText>
          </Logo>
          {/* <NewChatButton onClick={handleNavigateChat}> */}
          <NewChatButton href="/chat">
            <ButtonText>New Chat</ButtonText>
            <KeyboardShortcut>
              <ShortcutKey>k</ShortcutKey>
            </KeyboardShortcut>
          </NewChatButton>
          <NavLinks>
            {/* <NavLink onClick={handleNavigateExplore}>Explore bRAGs</NavLink>
            <NavLink onClick={handleNavigateChat}>My Chats</NavLink>
            <NavLink>My bRAGs</NavLink> */}

            {/* <NavLink>Interact with a YouTube video</NavLink>
            <NavLink>Talk to a book</NavLink>
            <NavLink>Explore a PDF</NavLink>
            <NavLink>Explore a website</NavLink>
            <NavLink>Research on steroids</NavLink>
            <NavLink>Ask anything</NavLink> */}

            {/* <NavLink onClick={handleNavigateCreate}>
              <FiPlusCircle style={{ marginRight: '8px' }} />
              Train your own bRAG
            </NavLink> */}
            <NavLink onClick={handleNavigateChat}>
              <FiMessageCircle style={{ marginRight: '8px' }} />
              Ask anything
            </NavLink>
            <NavLink onClick={handleNavigateCreate}>
              <FiGlobe style={{ marginRight: '8px' }} />
              Explore a website
            </NavLink>
            <NavLink onClick={handleNavigateGit}>
              <FiGithub style={{ marginRight: '8px' }} />
              Import GitHub repo
            </NavLink>
            <NavLink>
              <FiYoutube style={{ marginRight: '8px' }} />
              Interact with YouTube video
            </NavLink>
            {/* <NavLink>
              <FiBook style={{ marginRight: '8px' }} />
              Talk to books
            </NavLink> */}
            <NavLink>
              <FiFileText style={{ marginRight: '8px' }} />
              Explore PDFs
            </NavLink>
            <NavLink>
              <FiSearch style={{ marginRight: '8px' }} />
              Research on steroids
            </NavLink>
            <NavLink>
              <FiUser style={{ marginRight: '8px' }} />
              Create Your Digital Twin
            </NavLink>
          </NavLinks>
          <RecentSection>
            <SectionTitle>Recent</SectionTitle>
            <NoRecentChats>No recent chats</NoRecentChats>
          </RecentSection>
          {/* <AvatarSection>
            <SectionTitle>My Avatars</SectionTitle>
            <NoRecentAvatars>No recent avatars</NoRecentAvatars>
          </AvatarSection> */}
          <NavLinks>
            <NavLink onClick={handleNavigateExplore}>Explore bRAGs</NavLink>
            <NavLink>My Chats</NavLink>
            <NavLink>My bRAGs</NavLink>
          </NavLinks>
          {/* <UpgradeButton>
        <UpgradeText>Upgrade to Premium</UpgradeText>
        <UpgradeIcon
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b6d2fc82e0d243f27a05e0ace6353235c4d8ee9d367342093c1654f3287359e1?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88"
          alt="Upgrade icon"
        />
      </UpgradeButton> */}
        </>
      )}
    </SidebarContainer>
  );
};

const SidebarContainer = styled.aside<{ isOpen: boolean }>`
  width: ${(props) => (props.isOpen ? "260px" : "0px")};  /* Sidebar width changes based on state */
  // background: var(--color-grey-83, #d4d4d4);
  background: #fff;
  padding: ${(props) => (props.isOpen ? "24px" : "0px")};  /* Padding width changes based on state */
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;  /* Smooth transition */
  position: relative;  /* For positioning the toggle button */
`;

const ToggleButton = styled.button<{ isOpen: boolean }>`
  position: absolute;
  top: 50%;
  // right: -15px;
  right: ${(props) => (props.isOpen ? "-15px" : "-50px")};
  transition: width 0.7s ease;  /* Smooth transition */
  background-color: var(--color-grey-10, #18181b);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

const LogoImage = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 8px;
`;

const LogoText = styled.a`
  font-weight: 700;
  font-size: 24px;
  color: var(--color-grey-15, #262626);
  text-decoration: none;
`;

const AILink = styled.a`
  color: var(--color-grey-15, #262626);
  text-decoration: none;
  font-weight: 500;
`;

const NewChatButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--color-grey-10, #18181b);
  color: var(--color-grey-96, #f5f5f5);
  border: none;
  border-radius: 8px;
  padding: 8px 24px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 32px;
  text-decoration: none;
`;

const ButtonText = styled.span``;

const KeyboardShortcut = styled.div`
  display: flex;
  align-items: center;
`;

const ShortcutKey = styled.span`
  border: 1px solid var(--color-grey-64, #a3a3a3);
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 14px;
  margin-left: 8px;
`;

const NavLinks = styled.nav`
  display: flex;
  flex-direction: column;
  // margin-bottom: 32px;
`;

const NavLink = styled.a`
  display: flex;
  align-items: center;  /* Center items vertically */
  color: var(--color-grey-15, #262626);
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  // padding: 15px 0px;
  padding: 10px 0px;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;  /* Smooth transition for the transform */


  &:hover {
    // text-decoration: underline;  /* Add underline on hover */
    // animation: bounce 0.3s ease-in-out;  /* Apply the bounce animation on hover */
    transform: translateX(5px);  /* Move slightly to the right on hover */
  }

`;

const RecentSection = styled.section`
margin-top: 32px;
flex-grow: 1;
`;

const NoRecentChats = styled.p`
  font-size: 14px;
  color: var(--color-grey-15, #262626);
`;

// const AvatarSection = styled.section`
//   flex-grow: 1;
// `;

// const NoRecentAvatars = styled.p`
//   font-size: 14px;
//   color: var(--color-grey-15, #262626);
// `;

const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: var(--color-black-solid, #000);
  margin-bottom: 16px;
`;

// const UpgradeButton = styled.button`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   background: var(--color-white-solid, #fff);
//   color: var(--color-grey-25, #404040);
//   border: none;
//   border-radius: 8px;
//   padding: 8px 16px;
//   font-size: 14px;
//   font-weight: 500;
//   cursor: pointer;
// `;

// const UpgradeText = styled.span``;

// const UpgradeIcon = styled.img`
//   width: 16px;
//   height: 16px;
//   margin-left: 8px;
// `;

export default SidebarLight;

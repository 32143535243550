import React, { useState } from "react";
import styled from "styled-components";
import SidebarLight from "src/components/SidebarLight";
import SidebarDark from "src/components/SidebarDark";
import Header from "src/components/ChatInterface/Header";
interface Chatbot {
  id: number;
  name: string;
  description: string;
}
// A simple card component for displaying chatbot info
const ChatbotCard: React.FC<{ chatbot: Chatbot }> = ({ chatbot }) => {
  return (
    <Card>
      <CardHeader>{chatbot.name}</CardHeader>
      <CardBody>{chatbot.description}</CardBody>
      <CardFooter>
        <button>Use Chatbot</button>
      </CardFooter>
    </Card>
  );
};


interface ExploreInterfaceProps { 
  sidebar: boolean;
}

const ExploreInterface: React.FC<ExploreInterfaceProps> = ({ sidebar }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State to track sidebar visibility

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState); // Toggle sidebar state
  };
  // Using raw data instead of fetching from an API
  const chatbots: Chatbot[] = [
    {
      id: 1,
      name: "Code Tutor",
      description: "Let's code together! This tutor helps you write code with hints and suggestions.",
    },
    {
      id: 2,
      name: "Whimsical Diagrams",
      description: "Explains and visualizes concepts with flowcharts, mindmaps, and sequence diagrams.",
    },
    {
      id: 3,
      name: "Resume Helper",
      description: "By combining the expertise of top resume writers with advanced AI, we assist in creating impactful resumes.",
    },
    {
      id: 4,
      name: "Universal Primer",
      description: "The fastest way to learn anything hard by breaking down complex topics into simple steps.",
    },
  ];

  return (
    <ChatLayout>
      <MainContainer>
        {sidebar ? (
          <SidebarLight isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        ) : (
          <SidebarDark isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        )}
        <ContentArea>
          <Header
            sidebar={sidebar}
            knowledgeSource="{knowledgeSource}"
            isFolderDropdownOpen={false}
            toggleFolderDropdown={() => { }}
          />
          <ContentContainer>
            <Title>Browse bRAGs</Title>
            <CardGrid>
              {chatbots.map((chatbot) => (
                <ChatbotCard key={chatbot.id} chatbot={chatbot} />
              ))}
            </CardGrid>
          </ContentContainer>
        </ContentArea>
      </MainContainer>
    </ChatLayout>
  );
};

const ChatLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: var(--color-grey-4, #0a0a0a);
`;

const MainContainer = styled.main`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const ContentContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 80px;
  margin-top: 70px;
  overflow-y: auto;
  flex: 1;
`;

const ContentArea = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding: 20px;
`;

const Title = styled.h1`
  color: white;
  margin-bottom: 20px;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;

const Card = styled.div`
  background: #1c1c1c;
  border-radius: 8px;
  padding: 20px;
  color: white;
`;

const CardHeader = styled.h2`
  font-size: 1.5em;
`;

const CardBody = styled.p`
  font-size: 1em;
  margin-top: 10px;
`;

const CardFooter = styled.div`
  margin-top: 20px;
  text-align: right;
`;

export default ExploreInterface;

import React from "react";
import styled from "styled-components";

interface PreviewBannerProps {
  label: string;
}

const PreviewBanner: React.FC<PreviewBannerProps> = ({ label }) => {
  return (
    // <TempTop />
    <BannerWrapper>
      <HorizontalDivider />
      <BannerContent>
        <BannerText>
          {label}
        </BannerText>
        {/* <BannerIcon
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7e468b826aa43cd97d7eb42cb08042885cbce9821f8cd2e8b3ae956eb566cf96?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88"
          alt=""
        /> */}
      </BannerContent>
    </BannerWrapper>
  );
};

// const TempTop = styled.div`
//   margin-top: 270px;
// `

const BannerWrapper = styled.div`
  border-radius: 6px;
  background-color: rgba(38, 38, 38, 1);
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  margin-top: 60px;
  max-width: 100%;
  flex-direction: column;
  overflow: hidden;
  color: var(--brag-ai-mine-shaft, var(--color-grey-15, #262626));
  text-align: center;
  justify-content: start;
  padding: 1px;
  font: var(--font-weight-600, 600) 12px / var(--line-height-16, 16px)
    var(--font-family-Font-1, Inter);
  @media (max-width: 991px) {
    margin-top: 40px;
    margin-bottom: 20px; /* Adjusted margin bottom for smaller screens */
  }
  // margin-bottom: -100px;
`;

const HorizontalDivider = styled.div`
  background: var(
    --brag-ai-linear-heliotrope-heliotrope-heliotrope,
    linear-gradient(
      90deg,
      rgba(192, 132, 252, 0) 0%,
      rgba(192, 132, 252, 0.9) 50%,
      rgba(192, 132, 252, 0) 100%
    )
  );
  position: absolute;
  z-index: 0;
  display: flex;
  min-height: 1px;
  width: 345px;
  max-width: 100%;
  right: 16px;
  bottom: 0px;
  height: 1px;
`;

const BannerContent = styled.div`
  align-items: center;
  border-radius: 6px;
  background: var(--color-grey-90, #e5e5e5);
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
  z-index: 0;
  display: flex;
  width: 100%;
  gap: var(--item-spacing-8_01, 8.01px);
  overflow: hidden;
  justify-content: start;
  padding: 6px 16px;
`;

const BannerText = styled.p`
  align-self: stretch;
  margin: auto 0;
`;

const BannerIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  align-self: stretch;
  margin: auto 0;
`;

export default PreviewBanner;

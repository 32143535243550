import React, { useState } from "react";
import styled from "styled-components";
import { FiPlus, FiTrash } from "react-icons/fi";

export const KnowledgeBaseForm: React.FC = () => {
  const [urls, setUrls] = useState([{ id: Date.now(), value: "" }]);

  const handleAddUrl = () => {
    setUrls([...urls, { id: Date.now(), value: "" }]);
  };

  const handleRemoveUrl = (id: number) => {
    setUrls(urls.filter((url) => url.id !== id));
  };

  const handleUrlChange = (id: number, value: string) => {
    setUrls(urls.map((url) => (url.id === id ? { ...url, value } : url)));
  };

  return (
    <FormWrapper>
      <FormContainer>
        <FormContent>
          <FormHeader>
            <FormTitle>Configure Knowledge Base</FormTitle>
          </FormHeader>
          <FormDescription>
            Your knowledge base can include various web sources, like <br />
            documentation and more. The bRAG will be trained on all relevant
            sub-URLs sources.
          </FormDescription>
          <Form onSubmit={(e) => e.preventDefault()}>
            {urls.map((url, index) => (
              <InputGroup key={url.id}>
                {/* Only show label for the first input */}
                {index === 0 && (
                  <Label htmlFor={`apiDocUrl-${url.id}`}>
                    <span>Website URLs</span>
                    <RequiredMark>*</RequiredMark>
                  </Label>
                )}
                <InputContainer>
                  <Input
                    id={`apiDocUrl-${url.id}`}
                    type="url"
                    placeholder="https://your-knowledge-source.com"
                    value={url.value}
                    onChange={(e) => handleUrlChange(url.id, e.target.value)}
                    required
                  />
                  <ButtonWrapper>
                    {index > 0 && (
                      <RemoveButton
                        type="button"
                        onClick={() => handleRemoveUrl(url.id)}
                      >
                        <FiTrash size={16} />
                      </RemoveButton>
                    )}
                    <AddButton type="button" onClick={handleAddUrl}>
                      <FiPlus size={16} />
                    </AddButton>
                  </ButtonWrapper>
                </InputContainer>
              </InputGroup>
            ))}
            <SubmitButtonWrapper>
              <SubmitButton type="submit">Submit knowledge source</SubmitButton>
            </SubmitButtonWrapper>
          </Form>
        </FormContent>
      </FormContainer>
    </FormWrapper>
  );
};

// Styling for the input and buttons
const FormWrapper = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  flex: 1;
  flex-basis: 34px;
  padding: 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 512px;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FormContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FormHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FormTitle = styled.h2`
  color: #fff;
  text-align: center;
  font: 600 24px/32px Geist, sans-serif;
  min-width: 240px;
  padding: 8px 0 24px;
`;

const FormDescription = styled.p`
  width: 100%;
  padding-bottom: 24px;
  font-family: Geist, sans-serif;
  color: #d1d5db;
  font-weight: 400;
  line-height: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  padding: 16px 0 32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const InputGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 8px;
  font-family: Geist, sans-serif;
  color: #d4d4d4;
  font-weight: 500;
  line-height: 24px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const RequiredMark = styled.span`
  color: #ef4444;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const Input = styled.input`
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(40, 40, 40, 0.3);
  box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.1),
    0px 1px 0px 0px rgba(25, 28, 33, 0.02),
    0px 0px 0px 1px rgba(25, 28, 33, 0.08);
  width: 100%;
  padding: 11px 17px 10px;
  font-family: Geist, sans-serif;
  color: #9ca3af;
  font-weight: 400;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 8px;
  gap: 8px; /* Spacing between the add and remove buttons */
`;

const AddButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  color: #34d399;
  font-size: 16px; /* Reduced size */
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RemoveButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  color: #ef4444;
  font-size: 16px; /* Reduced size */
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  padding-bottom: 32px;
  flex-direction: column;
  align-items: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SubmitButton = styled.button`
  border-radius: 6px;
  background: #d4d4d4;
  color: #000;
  font-family: Geist, sans-serif;
  font-weight: 500;
  text-align: center;
  line-height: 20px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
`;


import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import HomePage from "./pages/HomePage";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ChatInterface from './pages/ChatInterface';
import ExploreInterface from './pages/ExploreInterface';
import CreateInterface from './pages/CreateInterface';
import GitInterface from './pages/GitInterface';
import GitChatInterface from './pages/GitChatInterface';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-DB1LP6JLQE');
// import reportWebVitals from './reportWebVitals';

const theme = {
  // Add any theme properties here if needed
};

const SideBarLight = false; // true for light theme, false for dark theme
const SideBarSources = false; // true for light theme, false for dark theme

const App = () => {
  // Google Analytics tracking
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/chat-123456" element={<ChatInterface sidebar={SideBarLight} sidebarSource={SideBarSources} />} />
          <Route path="/git-chat-123456" element={<GitChatInterface sidebar={SideBarLight} />} />
          <Route path="/create-123456" element={<CreateInterface sidebar={SideBarLight} />} />
          <Route path="/git-123456" element={<GitInterface sidebar={SideBarLight} />} />
          <Route path="/explore-123456" element={<ExploreInterface sidebar={SideBarLight} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Update the root render to use the App component
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import Header from "./Header";
import WorkspaceSection from "./WorkspaceSection";
import FileTree from "./FileTree";

interface ExplorerViewProps {
  // Add any props if needed
}

const ExplorerView: React.FC<ExplorerViewProps> = () => {
  return (
    <ExplorerContainer>
      <ExplorerContent>
        <ExplorerInner>
          <Header />
          <WorkspaceSection />
          <FileTree />
        </ExplorerInner>
      </ExplorerContent>
    </ExplorerContainer>
  );
};

const ExplorerContainer = styled.aside`
  // padding-left: 1px;
  border-left: 1px solid #2b2b2b;
  background-color: #0a0a0a;
  align-self: flex-end;
  display: flex;
  // min-height: 1220px;
  height: 100%;
  width: 254px;
  max-width: 100%;
  flex-direction: column;
  overflow: hidden;
`;

const ExplorerContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const ExplorerInner = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-start;
  flex: 1;
`;

export default ExplorerView;
